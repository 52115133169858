






































































































import { reactive, watch, computed, onMounted, onBeforeUpdate, onActivated } from "@vue/composition-api";
import { defineComponent } from '@vue/composition-api';
import { IReportData, IChartButton, IParameterData } from "@/store/stateDefinition/reportState"
import { ReportService } from '@/services/reportService'
import IParameterDataDatePicker from '@/components/IParameterDataDatePicker.vue'
import GroupedMultiSelect from '@/components/GroupedMultiSelect.vue'
import SingleSelect from '@/components/SingleSelect.vue'
import TextComponent from '@/components/TextComponent.vue'
import DrillDownDetails from '@/components/DrillDownDetails.vue'
//import TextboxComponent from '@/components/TextboxComponent.vue'
import AutocompleteComponent from '@/components/AutocompleteComponent.vue'
import { ExportService, ExportFormat } from '@/services/exportService'
import { TableReport } from '../services/tableReport';
import store from '../store';
import { ReportActions } from '@/store/actions/reportActions'
import { useWindowSize } from 'vue-window-size';
import Multiselect from 'vue-multiselect'
import { DrillDownReport } from '../services/drilldownReport'
import { DataOptions, DataTableHeader } from 'vuetify/types'
import { set } from 'vue/types/umd';
import staticAxios from 'axios'
import axios, { CancelTokenSource } from 'axios';
// @page-count="state.maxPages = $event"
export default defineComponent({
  name: 'TableReportComponent',
  components: {
    IParameterDataDatePicker, Multiselect, GroupedMultiSelect,
    SingleSelect, TextComponent, AutocompleteComponent, DrillDownDetails
  },
  props: {
    report: {
      type: Object,
      required: true
    },
    isDialog: {
      type: String,
      required: false
    }
  },
 
  setup(props: any) {
    const reportData: IReportData = reactive(props.report);
    TableReport.setDateParameters(reportData);
    
    let activeReq: CancelTokenSource | null//= staticAxios.CancelToken.source();
    const state = reactive({
      report: {},
      // chart: {},
      loaded: false,
      busy: false,
      exportError: false,
      //options: {},
      vcardStyle: {
        maxHeight: "500px",
      },
      NuberOfPassengers: '',
      NumberOfSubmissions: '',
      activeReq: staticAxios.CancelToken.source(),

    });
    const reportService = new ReportService();
    const itemFormat = reportService.TableItemFormat;

    const userSelectionChange = computed((): boolean => store.getters["reportState/userSelectionChange"])
      watch(userSelectionChange, (newValue, oldValue) => {
        let report = state.report as TableReport;
        if (report.clearChartAndData !== undefined) {
          report.clearChartAndData();
          state.loaded = false;
        }
      });

  
    const cancelLoad = () => {
      console.log('cancel load');
      if (state.activeReq != null) {
        state.activeReq.cancel('Request was cancelled by user');
      }
      //state.activeReq = null;
    }
    const loadReportData = () => {
      state.loaded = false;
      state.busy = true;
      
      let report = state.report as TableReport;
      if (report.clearChartAndData !== undefined) {
        report.clearChartAndData();
        
      }
      state.activeReq = staticAxios.CancelToken.source();

      reportService.loadDistinctPassengers(reportData,  state.activeReq)
        .then((nreport) => {

          state.report = nreport as TableReport;
          state.loaded = true;
          state.busy = false;

          
          if (nreport.tableData != null) {
            state.NuberOfPassengers = JSON.stringify((nreport.tableData as Array<{ numberOfPassengers: number, numberOfSubmissions:number}>)[0].numberOfPassengers);//[0];
            state.NumberOfSubmissions = JSON.stringify((nreport.tableData as Array<{ numberOfPassengers: number, numberOfSubmissions: number }>)[0].numberOfSubmissions);//nreport.tableData[0][1];

          }
          //state.maxPages = Math.ceil(nreport.totalRows / parseInt(state.mRowsPerPage, 10));//nreport.getMaxPages();
          //currentPage.value = 1;
        })
        .catch((e) => {
          if (typeof e === "string") {
            store.dispatch("reportState/" + ReportActions.SetErrorBar, e)
          } else
            if (axios.isCancel(e)) {
              store.dispatch("reportState/" + ReportActions.SetErrorBar, e.message)
            }
            else {
            
            store.dispatch("reportState/" + ReportActions.SetErrorBar, 'Error loading the report')
          }
          state.loaded = false;
          state.busy = false;
          console.log(e);
        })
    }
    const printReport = () => {
      var documenttitle = document.title;
      var printtitle = reportData.title;
      if (reportData.parameters != null) {
        for (var i = 0; i < reportData.parameters.length; i++) {
          if (reportData.parameters[i].value != null) {
            printtitle += ', ' + reportData.parameters[i].label + '=' + reportData.parameters[i].value;
          }
          
        }
      }
      
      document.title = printtitle;

        //document.title + ' ' + $('.serverclock span.placeholder').attr('data-offset') + ' ' + $('.serverclock span.placeholder').html().substr(0, 5).replace(':', '.');
      window.print();
      document.title = documenttitle;
     }
    const exportReport = () => {
      let report = state.report as TableReport;
      if (report.tableData === undefined) {
        store.dispatch("reportState/" + ReportActions.SetErrorBar, "Report not loaded");
        return;
      }
      let exportService = new ExportService(report, ExportFormat.XLS);
      state.busy = true;
      exportService.exportReport().then(() => { state.busy = false; }).catch(err => {
        store.dispatch("reportState/" + ReportActions.SetErrorBar, "Error exporting the report");
        state.busy = false;
      });
    }
    const getItemClass = (item: any): any => {
      return ['text', 'text', 'num'];
    }
    const customSort = (items: any, index: any, isDesc: any): any => {
      //loadReportData()
    }//reportService.tableCustomSort;
    const rowClass = (item: any) => {
      if (item.isTotal === undefined)
        return '';
      if (item['isTotal'] == 1)
        return 'total';
      else
        return '';
    }
    const extraSmallSize = (param: IParameterData): number => {
      if (param.type == 'date' || param.type == 'month')
        return 6;
      if (param.type == 'text')
        return 6;
      if (param.type == 'GroupedMultiSelect')
        return 12;

      return 6;
    }
    const smallSize = (param: IParameterData): number => {
      if (param.type == 'date' || param.type == 'month')
        return 4;
      if (param.type == 'text')
        return 4;
      if (param.type == 'GroupedMultiSelect')
        return 12;

      return 6;
    }
    const mediumSize = (param: IParameterData): number => {
      if (param.type == 'date' || param.type == 'month')
        return 3;
      if (param.type == 'text')
        return 3;
      if (param.type == 'GroupedMultiSelect')
        return 6;

      return 4;
    }
    const largeSize = (param: IParameterData): number => {
      if (param.type == 'date' || param.type == 'month')
        return 3;
      if (param.type == 'text')
        return 3;
      if (param.type == 'GroupedMultiSelect')
        return 6;

      return 4;
    }
    
    onMounted(() => {
      loadReportData();
    });
    return {
      state,
      reportData,
      //afterChartCreate,
      loadReportData,
      exportReport,
      reportService,
      getItemClass,
      customSort,
      rowClass,
      itemFormat,
      largeSize,
      mediumSize,
      smallSize,
      extraSmallSize,
      cancelLoad,
      printReport
    }
  }
});
