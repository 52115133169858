





















import Navigation from '@/components/Navigation.vue'
import { reactive, computed, watch } from "@vue/composition-api";
import store from "@/store/index";
import ReportFullComponent from '@/components/ReportFullComponent.vue'
import TableReportComponent from '@/components/TableReportComponent.vue'
import ReportStockChartComponent from '@/components/ReportStockChartComponent.vue'
import ReportCTRIPComponet from '@/components/ReportCTRIPComponet.vue'
import router from '@/router'
import { ReportService } from '@/services/reportService'
import { ReportActions } from '@/store/actions/reportActions'
import MonthlySummariesReport from '@/components/MonthlySummariesReport.vue'

export default {
  components: {
    Navigation,
    ReportFullComponent,
    MonthlySummariesReport,
    TableReportComponent,
    ReportStockChartComponent,
    ReportCTRIPComponet
  },
  setup() {
    const reportService = new ReportService();

    const ready = computed((): boolean => store.getters["reportState/readygetter"]);
    const readyDate = computed((): number => store.getters["reportState/readyDate"]);
    const selectedReportId = computed((): number => store.getters["reportState/selectedReportId"]);
    const selectedReportUrl = computed((): string => store.getters["reportState/selectedReportUrl"]);
    const dialogReport = computed((): number => store.getters["reportState/dialogReportGetter"]);
    const dialogReportKey = computed((): number => store.getters["reportState/dialogReportKeyGetter"]);


    watch(readyDate, (newValue, oldValue) => {
      const routeVal = router.currentRoute.params.reportUrl;
      if (routeVal !== undefined && routeVal !== selectedReportUrl.value) {
        store.dispatch("reportState/" + ReportActions.SetSelectedReportUrl, routeVal);
      }
    });

    const report = computed(() => {
      return reportService.loadReportFromStore(selectedReportId.value);
    });

    const state = reactive({
      showReportDialog: false,
    });
    watch(dialogReportKey, (newValue, oldValue) => {
      if (dialogReportKey.value > 0)
        state.showReportDialog = true;
    });

    return {
      ready,
      readyDate,
      selectedReportId,
      report,
      dialogReport,
      dialogReportKey,
      state
    }
  }
}
