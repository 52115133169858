import { IReportData } from '@/store/stateDefinition/reportState'
import { Axis, SeriesOptionsType } from 'highcharts';
import { ReportService } from './reportService';
import { ChartService } from '@/services/chartService'
import { TooltipFormatterCallbackFunction, SeriesClickCallbackFunction } from 'highcharts'
import * as Highcharts from 'highcharts';

export class Report {
  reportData: IReportData;
  chart: Highcharts.Chart | null;
  chartSeries: any[] | null;
  chartCategories: any;
  tableData: {} | null;
  tableTotals: any;
  tableHeaders: {};
  pageSize: number;
  currentPage: number;
  currentSortBy: string;
  currentSortDesc: boolean;
  serieData: any[] | null;
  visibleRowsCount: number;
  firstChartSeries: any[] | null;

  constructor(reportData: IReportData, chart: Highcharts.Chart|null, chartSeries: any, tableData: {}, tableHeaders: {}, chartCategories: string[] | null) {
    this.reportData = reportData;
    this.chart = chart;
    if (reportData.isMap && chart != null) {
      this.chartSeries = ChartService.GetMapSeries(chart, chartSeries);
    }
    else {
      this.chartSeries = chartSeries;
    }
    this.firstChartSeries = null;

    this.tableData = tableData;
    this.tableTotals = null;
    if (tableData != null && Array.isArray(tableData) && tableData.length > 0) {
      if (tableData[0].isTotal !== undefined) {
        this.tableTotals = tableData.filter(t => t.isTotal);
        this.tableData = tableData.filter(t => !t.isTotal);
      }
    }

    this.tableHeaders = tableHeaders;
    this.chartCategories = chartCategories;
    this.currentPage = 0;
    this.pageSize = 99999;
    this.currentSortBy = '';
    this.currentSortDesc = true;
    this.serieData = this.chartSeries?.map(t => t.data) || null;
    this.visibleRowsCount = 0;


  }
  static setDateParameters(reportData: IReportData) {
    if (reportData.parameters != null) {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, '0');
      var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = today.getFullYear();

      for (let i = 0; i < reportData.parameters.length; i++) {
        let p = reportData.parameters[i];
        switch (p.type) {
          case 'date':
            p.value = yyyy + '-' + mm + '-' + dd;
              
            break;
          case 'month':
            p.value = yyyy + '-' + mm;
            break;
        }
      }
    }
  }
  setFirstChartSeries(series: any[] | null) {
    this.firstChartSeries = series;
    this.updateChartContent();
  }
  isColumnNumeric(columnName: string) {
    let newseries = this.serieData;
    if (newseries != null && newseries.length > 0) {
      for (var i = 0; i < newseries.length; i++) {
        let serie = newseries[i];
        for (var n = 0; n < serie.length; n++) {
          let ta = serie[n][columnName];
          if (ta !== null && typeof ta !== 'number')
            return false;
        }
      }
    }
    return true;
  }  
  sortByColumn(columnName: string) {
    let newseries = this.serieData;
    let reportSerice = new ReportService();
    if (newseries != null && newseries.length>0) {
      if (columnName === this.currentSortBy) {
        this.currentSortDesc = !this.currentSortDesc;
      }
      else {
        this.currentSortDesc = this.isColumnNumeric(columnName);
        this.currentSortBy = columnName;
      }
      for (var i = 0; i < newseries.length; i++) {
        let serieDataI = newseries[i];
        if(serieDataI!==null)
          reportSerice.tableCustomSort(serieDataI, columnName, this.currentSortDesc);
      }
    }
    this.updateChartContent();
  }
  
  updateChartContent() {
    if (this.chart == null)
      return;
    let newseries = this.serieData;

    if (newseries != null && this.chartSeries != null) {
      if (this.chart.series !== undefined) {
        for (let i = this.chart.series.length - 1; i >= 0; i--) {
          this.chart.series[i].remove(true);
        }
      }
      if (this.firstChartSeries != null) {
        for (let i = 0; i < this.firstChartSeries.length; i++) {
          this.chart.addSeries(this.firstChartSeries[i]);
        }
      }
      for (let i = 0; i < newseries.length; i++) {
        let chartSerie = this.chartSeries[i];
        let serie = newseries[i];
        if (serie !== undefined) {
          if (this.reportData.chartPagination == true && serie.length > this.pageSize) {
            let start = this.pageSize * this.currentPage;
            let end = start + this.pageSize;
            if (end >= serie.length)
              serie = serie.slice(start);
            else
              serie = serie.slice(start, end);
          }

          if (serie.length > 0 && serie[0]["chartCategory"] !== undefined)
            this.chartCategories = serie.map((t: any) => t["chartCategory"]);

          chartSerie.data = serie;

          this.visibleRowsCount = serie.length;
        }
        try {
          this.chart.addSeries(chartSerie);
        } catch {
          return;
        }
      }
    }
    if (this.chartCategories != null) {
      this.chart.xAxis.forEach((x: Axis) => {
        x.setCategories(this.chartCategories);
      });
    }

    if (this.chart.series.length > 0) {
      let chartService = new ChartService();
      let tooltipFn = chartService.tooltipFormatter(this);
      if (tooltipFn != null) {
        this.chart.tooltip.options.formatter = tooltipFn as TooltipFormatterCallbackFunction;
      }
      if (this.reportData.serieClick != null) {
        (this.chart as any).myReportObject = this;
        for (let iser = 0; iser < this.chart.series.length; iser++) {
          Highcharts.addEvent(this.chart.series[iser], 'click', Report.serieClick);
        }
      }
    }
    this.chart.redraw();
  }

  static serieClick(event: any) {
    let self = event.point.series.chart.myReportObject;    
    const sk = self.reportData.serieClick;
    if (sk == null)
      return;
    const reportService = new ReportService();
    const newReportData = reportService.loadReportFromStore(parseInt(sk.reportId));
    if (newReportData == null)
      return;
    if (self.reportData.parameters != null && newReportData.parameters != null) {
      for (let i = 0; i < sk.parametersMapping.length; i++) {
        const map = sk.parametersMapping[i];
        const dest = newReportData.parameters.find(t => t.paramname == map.destinationName);
        if (dest != null) {
          let value = '';
          if (map.pointSelectionKey != null) {
            value = event.point[map.pointSelectionKey];
          }
          else {
            const orig = self.reportData.parameters.find((t:any) => t.paramname == map.originName);
            if (orig !== undefined) {
              value = orig.value;
            }
            else {
              console.log("serieClick - origin parameter not found:" + map.originName);
            }
          }
          dest.value = value;
        }
        else {
          console.log("serieClick - destination parameter not found:" + map.destinationName);
        }
      }
    }
    if (sk.composedTitle != null) {
      let title = '';
      for (let i = 0; i < sk.composedTitle.length; i++) {
        const ct = sk.composedTitle[i];
        if (ct.paramValue != null && newReportData.parameters != null) {
          const dest = newReportData.parameters.find(t => t.paramname == ct.paramValue);
          if (dest != null) {
            title += dest.value;
          }
          else {
            console.log("serieClick - title parameter not found:" + ct.paramValue);
          }
        }
        else if (ct.text != null) {
          title += ct.text;
        }
      }
      newReportData.dialogTitle = title;
    }

    reportService.showDialogChart(newReportData);
  }
  getMaxSerieLenght(): number {
    let newseries = this.serieData;
    let max = 0;
    if (newseries != null) {
      for (var i = 0; i < newseries.length; i++) {
        let serie = newseries[i];
        if (max < serie.length)
          max = serie.length;
      }
    }
    return max;
  }
  getMaxPages() : number{
    if (this.pageSize <= 0)
      return 0;
    let max = this.getMaxSerieLenght();
    let pages = max / this.pageSize;
    let intPages = ~~pages;
    if (intPages < pages)
      intPages++;
    return intPages;
  }
  setPageSize(newsize: number) {
    this.pageSize = newsize;
    let max = this.getMaxPages();
    if (this.currentPage > max)
      this.currentPage = max;
    this.updateChartContent();
  }
  setPage(newpage: number) {
    this.currentPage = newpage;
    if (this.currentPage < 0)
      this.currentPage = 0;
    let max = this.getMaxPages();
    if (this.currentPage > max)
      this.currentPage = max;
    this.updateChartContent();
  }
  clearChartAndData() {
    if (this.chart != null) {
      for (var i = this.chart.series.length - 1; i >= 0; i--) {
        this.chart.series[i].remove(true);
      }
      this.chart.redraw();
    }
    this.tableData = null;
    this.serieData = null;
  }
}
