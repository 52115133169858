
















import { reactive } from "@vue/composition-api";
import { defineComponent } from '@vue/composition-api';
import { IReportData } from "@/store/stateDefinition/reportState"
import { ReportService } from '@/services/reportService'
import { Report } from '../services/report';
import store from "@/store/index";
import { ReportActions } from '@/store/actions/reportActions'
import router from '@/router'

export default defineComponent({
  name: 'ReportComponent',
  props: {
    report: {
      type: Object,
      required: true
    }
  },
  setup(props: any) {
    const reportData: IReportData = props.report;
    Report.setDateParameters(reportData);

    const state = reactive({
      report: {},
      loaded: false,
    });
    const reportService = new ReportService();

    const afterChartCreate = (chart: Highcharts.Chart) => {
      reportService.loadReportData(reportData, chart).then((nreport) => {
        state.report = nreport;
        state.loaded = true;
      })
    }
    const detailsClick = (id: number) => {
      store.dispatch("reportState/" + ReportActions.SetSelectedReportId, id);
      if (id == 0) {
        router.push('/');
      }
      else {
        router.push(reportService.reportUrlReportId(id));
      }
    }
    return {
      state,
      afterChartCreate,
      reportService,
      detailsClick
    }
  }
});
