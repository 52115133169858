import axios from "@/config/axiosSetup"
import { AxiosRequestConfig } from "axios"
import { Report } from '@/services/report'
import { UserService } from '@/services/userService'
import store from '@/store'
import { ChartService } from './chartService'

const baseURL = (window as any).VueConfig.VUE_APP_ROOT_API + "report/";

export class SummaryFileService {
  userService: UserService;
  report: Report;
  downloadfilename: string;

  constructor(report: Report, downloadfilename: string) {
    this.downloadfilename = downloadfilename;
    this.userService = new UserService();
    this.report = report;
  }
  async downloadFile(): Promise<void> {
    const self = this;
    return new Promise(function (resolve, reject) {
      try {
        const path = self.downloadfilename.split('/');
        const FileName = path[path.length - 1].replace('%20', ' ');

        let data: any = {};
        data['groupValue'] = self.downloadfilename;
        data['airlinesId'] = self.userService.selectedAirlinesIdAsString();
        data['configurationsId'] = self.userService.selectedConfigsIdsAsString();
        data['timeZoneId'] = store.state.reportState?.selectedTimeZone?.id;
        const url = baseURL + 'GetMonthlySummaryFile';

        const config: AxiosRequestConfig = {
          responseType: "blob"
        };
        return axios
          .post(url, data, config)
          .then(
            response => {
              var fileURL = window.URL.createObjectURL(new Blob([response.data]));
              var fileLink = document.createElement('a');

              fileLink.href = fileURL;
              fileLink.setAttribute('download', FileName);

              document.body.appendChild(fileLink);
              fileLink.click();
              document.body.removeChild(fileLink);
              resolve();
            },
            err => {
              reject(err);
            }
          )
          .catch(function (error: any) {
            reject(error);
          });
      }
      catch(error) {
        reject(error);
      };
    });
  }
}
