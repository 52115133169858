import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

const vuetify = new Vuetify({
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        anchor: '#007bff',
        primary: '#0b416d',
        secondary: '#BA9DCC',
        accent: '#82b1ff',
        error: '#ff5252',
        info: '#2196f3',
        success: '#4caf50',
        warning: '#fb8c00',
        text: '#0a406d',
        background: '#f5f5f5',
        topbarbackground:'#f6f5ff',
        backgroundbase: '#f5f5f5'
      },
    },
  },
})

export default vuetify
