import axios from "@/config/axiosSetup"
import staticAxios, { CancelTokenSource } from 'axios'
import { ReportAirlineState, AirlineConfigState, IReportData, AirlineConfigUniqueNameState, ReportTimeZone, IParameterData } from '@/store/stateDefinition/reportState'
import { UserService } from '@/services/userService'
import { TableReport } from '@/services/tableReport'
import { Report } from '@/services/report'

import allReportsFile from '@/assets/reports.json'
import store from '@/store'
import { config } from 'vue/types/umd'
import axiosRetry from 'axios-retry';
import { ReportActions } from '@/store/actions/reportActions'
import { DrillDownReport } from './drilldownReport'

const baseURL = (window as any).VueConfig.VUE_APP_ROOT_API + "report/";

const AxiosRetriesCount = 10;

export class ReportService {
  userService: UserService;

  constructor() {
    this.userService = new UserService();
  }
  fetchReports(): Promise<IReportData[]> {
    return new Promise(function (resolve) {
      resolve(allReportsFile as unknown as IReportData[]);
    })
  }
  reportUrlReportId(reportId: number): string {
    const reports = store.state.reportState?.allReports.filter(t => t.id == reportId);
    if (reports?.length == 1)
      return '/reports/' + reports[0].reportUrl;
    return '/';
  }
  reportUrl(report: IReportData): string {
    return '/reports/' + report.reportUrl;
  }
  fullReportUrl(report: IReportData): string {
    const reports = store.state.reportState?.allReports.filter(t => t.id == report.fullReportId);
    if (reports?.length == 1)
      return '/reports/' + reports[0].reportUrl;
    return '';
  }
  //async getTimeZones(): Promise<ReportTimeZone[] | null> {
  //  let self = this;
  //  return new Promise(function (resolve, reject) {
  //    self.userService.getToken()
  //      .then((access_token) => {
  //        const url = baseURL + "GetTimeZones";

  //        const config = {
  //          headers: {
  //            'Content-Type': 'application/json',
  //            'Authorization': 'Bearer ' + access_token
  //          }
  //        };
  //        let timezones: ReportTimeZone[] = [];

  //        axiosRetry(axios, { retries: AxiosRetriesCount });
  //        axios
  //          .post(url, null, config)
  //          .then(
  //            response => {
  //              timezones.push(...response.data.map((item: { id: number; standardName: string; Name: string }) => { return item }));
  //              resolve(timezones);
  //            },
  //            err => { reject(err); }
  //          )
  //          .catch(function (error: any) {
  //            reject(error);
  //          });
  //      })
  //      .catch(function (error: any) {
  //        reject(error);
  //      });
  //      ;
  //  });
  //}
  async getTimeZones(): Promise<ReportTimeZone[] | null> {
    let self = this;
    return new Promise(function (resolve, reject) {
      try {
        const url = baseURL + "GetTimeZones";
        let timezones: ReportTimeZone[] = [];

        axiosRetry(axios, { retries: AxiosRetriesCount });
        axios
          .post(url, null)
          .then(
            response => {
              timezones.push(...response.data.map((item: { id: number; standardName: string; Name: string }) => { return item }));
              resolve(timezones);
            },
            err => { reject(err); }
          )
          .catch(function (error: any) {
            reject(error);
          });
      }
      catch (error) {
        reject(error);
      }
    });
  }

  async getReportAirlines(): Promise<ReportAirlineState[] | null> {
    let self = this;
    return new Promise(function (resolve, reject) {
      try {
        const url = baseURL + "GetReportAirlines";
        let airlines: ReportAirlineState[] = [{ airlineId: 0, iata: 'All' }];

        axiosRetry(axios, { retries: AxiosRetriesCount });
        axios
          .post(url, null)
          .then(
            response => {
              airlines.push(...response.data.map((item: { airlineId: any; iata: any; }) => { return item }));
              resolve(airlines);
            },
            err => { reject(err); }
          )
          .catch(function (error: any) {
            reject(error);
          });
      }
      catch(error) {
        reject(error);
      };
    });
  }

  async getAirlineConfigurations(): Promise<AirlineConfigState[] | null> {
    let self = this;
    return new Promise(function (resolve, reject) {
      try {
        const url = baseURL + "GetAirlineConfigurations";

        let airlineConfigs: AirlineConfigState[] = [{ airlineId: 0, configId: 0, name: 'All' }];

        axiosRetry(axios, { retries: AxiosRetriesCount });
        axios
          .post(url, null)
          .then(
            response => {
              airlineConfigs.push(...response.data.map((item: { airlineId: any; configId: any; name: any; }) => { return item }));
              resolve(airlineConfigs);
            },
            err => { reject(err); }
          )
          .catch(function (error: any) {
            reject(error);
          });
      }
      catch(error) {
        reject(error);
      };
    });
  }

  getAirlineConfigsNames(allAirlineConfigs: AirlineConfigState[], selectedAirlines: ReportAirlineState[], addAll:boolean) : string[] {
    let rv : string[] = []
    let allSelected = selectedAirlines.find(t => t.airlineId == 0) !== undefined;
    if (addAll)
      rv.push('All');
    for (let i = 0; i < allAirlineConfigs.length; i++) {
      let a = allAirlineConfigs[i];
      let nameInList = rv.find(t => t == a.name) !== undefined;
      let airlineSelected = allSelected ||  selectedAirlines.find(t => t.airlineId == a.airlineId) !== undefined;
      if (airlineSelected && !nameInList)
        rv.push(a.name);
    }
    return rv;
  }
  getSelectedAirlineConfigsNames(configNames: string[], selectedNames:string[]) : string[] {
    let rv: string[] = []
    for (let i = 0; i < selectedNames.length; i++) {
      let a = selectedNames[i];
      let nameInList = configNames.find(t => t == a) !== undefined;
      if(nameInList)
        rv.push(a);
    }
    if (rv.length == 0)
      rv.push(configNames[0]);
    return rv;
  }

  joinedAirlines(selectedAirlines: ReportAirlineState[] | null) {
    let result = "";
    let separator = ",";
    if (selectedAirlines != null) {
      for (let i = 0; i < selectedAirlines.length; i++) {
        if (result != "") {
          result += separator;
        }
        result += selectedAirlines[i].iata;
      }
    }
    return result;
  }

  joinedConfigs(selectedConfigs: string[] | null) {
    let result = "";
    let separator = ",";
    if (selectedConfigs != null) {
      for (let i = 0; i < selectedConfigs.length; i++) {
        if (result != "") {
          result += separator;
        }
        result += selectedConfigs[i];
      }
    }
    return result;
  }
  static airlineSelectionFilter(activeSelection: ReportAirlineState[], allAirlines: ReportAirlineState[], newSelection: ReportAirlineState[]): ReportAirlineState[] {
    let allBefore = activeSelection.filter(t => t.airlineId == 0).length > 0;
    if (newSelection.length > 1) {
      let allNew = newSelection.filter(t => t.airlineId == 0).length > 0;
      if (allNew && !allBefore)
        return newSelection.filter(t => t.airlineId == 0);
      else
        return newSelection.filter(t => t.airlineId > 0);
    }
    else if (newSelection.length == 0) {
      if (allBefore)
        return allAirlines.filter(t => t.airlineId > 0);
      else
        return allAirlines.filter(t => t.airlineId == 0);
    }
    else
      return newSelection;
  }
  static configurationSelectionFilter(activeSelection: string[], allConfigs: string[], newSelection: string[]): string[] {
    let allBefore = activeSelection.filter(t => t == 'All').length > 0;
    if (newSelection.length > 1) {
      let allNew = newSelection.filter(t => t == 'All').length > 0;
      if (allNew && !allBefore)
        return newSelection.filter(t => t == 'All');
      else
        return newSelection.filter(t => t != 'All');
    }
    else if (newSelection.length == 0) {
      if (allBefore)
        return allConfigs.filter(t => t != 'All');
      else
        return allConfigs.filter(t => t == 'All');
    }
    else
      return newSelection;
  }
  async loadDrillDown(item: any): Promise<DrillDownReport> {
    let self = this;
    return new Promise(function (resolve, reject) {
      //self.userService.getToken()
      //  .then((access_token) => {
      try {
        let aparam: any = {};
        aparam['id'] = 0; 
        aparam['paramname'] = 'passengerGUID';
        aparam['name'] = 'passengerGUID';
        aparam['label'] = 'Passenger GUID';
        aparam['options'] = null;
        aparam['value'] = item.passengerGUID;
        aparam['type'] = 'text'
        aparam['mandatory'] = true;

        let params = [aparam];
        let reportData: any = {};

        reportData['id'] = 9990;
        reportData['dialogTitle'] = null;
        reportData['hideFromList'] = true;
        reportData['customReportType'] = null;
        reportData['name'] = 'DrillDownDetails';
        reportData['title']='Drill Down Details';
        reportData['isFullReport'] = false;
        reportData['isMap'] = false;
        reportData['fullReportId'] = 0; 
        reportData['parameters'] = params;

        let data: any = {};
        if (reportData.parameters != null) {
          for (let i = 0; i < reportData.parameters.length; i++) {
            let p = reportData.parameters[i];
            if (p.mandatory == true && (p.value == '' || p.value == null)) {
              reject('Missing value for ' + p.label);
              return;
            }
            if (p.type == 'month' && p.value.split('-').length == 2)
              p.value = p.value + '-01';
            data[p.paramname] = p.value;
          }
        }
          //data['passengerGUID'] = item.passengerGUID;
        data['name'] = reportData.name;
        data['airlinesId'] = self.userService.selectedAirlinesIdAsString();
        data['configurationsId'] = self.userService.selectedConfigsIdsAsString();
        data['timeZoneId'] = store.state.reportState?.selectedTimeZone?.id;
        //console.log('loadReportData', data)
        //let var_url = reportData.customReportType == 'PassengerDetails' ? "GetTableData" : "GetReportData";
        const url = baseURL + "GetPassengerDetails";

        const config = {
          transformResponse: (res: string) => {
            var reISO = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*))(?:Z|(\+|-)([\d|:]*))?$/;
            var reMsAjax = /^\/Date\((d|-|.*)\)[\/|\\]$/;

            let dateReviver = function (key: any, value: any) {
              // plug this regex into regex101.com to understand how it works
              // matches 2019-06-20T12:29:43.288 (with milliseconds) and 2019-06-20T12:29:43 (without milliseconds)
              var dateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{1,}|)$/;
              if (typeof value === "string" && dateFormat.test(value)) {
                let rv = new Date(value + 'Z');
                return rv;
              }

              return value;
            };
            try {
              return JSON.parse(res, dateReviver);
            } catch {
              console.log('error transforming string', res);
            }
          }
        };

          axiosRetry(axios, { retries: AxiosRetriesCount });
          axios
            .post(url, data, config)
            .then(
              response => {
                //var pax=response.data
                let ddReport = new DrillDownReport(reportData,
                  response.data.tableData,
                  response.data.tableHeaders
                );
                //udpReport.setPageSize(10);
                //resolve(pax);
                resolve(ddReport);
              },
              err => { reject(err); }
            )
            .catch(function (error: any) {
              reject(error);
            });
      }
      catch (error) {
          reject(error);
        };
    });
  }
  async loadTableHeaders(reportData: IReportData): Promise<TableReport> {
    let self = this;
    return new Promise(function (resolve, reject) {
      try {
        const url = baseURL + "GetPassengerTableHeaders";
        axiosRetry(axios, { retries: AxiosRetriesCount });
        axios
          .get(url)
          .then(
            response => {
              let udpReport = new TableReport(reportData,
                response.data.tableData,
                response.data.tableHeaders
              );
              //udpReport.setPageSize(10);
              resolve(udpReport);
            },
            err => { reject(err); }
          )
          .catch(function (error: any) {
            reject(error);
          });
      }
      catch (error) {
        reject(error);
      };
    })
  }
  async loadDistinctPassengers(reportData: IReportData, request: CancelTokenSource): Promise<TableReport>
  {
    let self = this;
    return new Promise(function (resolve, reject) {
      try {
        let data: any = {};
        if (reportData.parameters != null) {
          for (let i = 0; i < reportData.parameters.length; i++) {
            let p = reportData.parameters[i];
            if (p.mandatory == true && (p.value == '' || p.value == null)) {
              reject('Missing value for ' + p.label);
              return;
            }
            if (p.type == 'month' && p.value.split('-').length == 2)
              p.value = p.value + '-01';
            data[p.paramname] = p.value;
          }
        }

        if (self.userService.isCTRIP()==false) {
          reject('This is a report only for C-Trip. Please select C-Trip to see this report.');
          return;
        }
        data['name'] = reportData.name;

        data['timeZoneId'] = store.state.reportState?.selectedTimeZone?.id;
        //console.log('loadReportData', data)
        //let var_url = reportData.customReportType == 'PassengerDetails' ? "GetTableData" : "GetReportData";
        const url = baseURL + "GetReportData";

        const config = {
          transformResponse: (res: string) => {
            var reISO = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*))(?:Z|(\+|-)([\d|:]*))?$/;
            var reMsAjax = /^\/Date\((d|-|.*)\)[\/|\\]$/;

            let dateReviver = function (key: any, value: any) {
              // plug this regex into regex101.com to understand how it works
              // matches 2019-06-20T12:29:43.288 (with milliseconds) and 2019-06-20T12:29:43 (without milliseconds)
              var dateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{1,}|)$/;
              if (typeof value === "string" && dateFormat.test(value)) {
                let rv = new Date(value + 'Z');
                return rv;
              }

              return value;
            };
            try {
              return JSON.parse(res, dateReviver);
            } catch {
              console.log('error transforming string', res);
            }
          },
          cancelToken: request.token
        };
        axiosRetry(axios, { retries: AxiosRetriesCount });

        axios
          .post(url, data, config)
          .then(
            response => {
              let udpReport = new TableReport(reportData,
                response.data.tableData,
                response.data.tableHeaders
              );
              udpReport.totalRows = response.data.totalRows;
              //udpReport.setPageSize(10);
              resolve(udpReport);
            },
            err => { reject(err); }
          )
          .catch(function (error: any) {
            reject(error);
          });


      }
      catch (error) {
        reject(error);
      };
    });
  }
  async loadTableData(reportData: IReportData, pagingData: any, request: CancelTokenSource): Promise<TableReport>
  {
    let self = this;
    return new Promise(function (resolve, reject) {
      //self.userService.getToken()
      //  .then((access_token) => {
      try {
          let data: any = {};
          if (reportData.parameters != null) {
            for (let i = 0; i < reportData.parameters.length; i++) {
              let p = reportData.parameters[i];
              if (p.mandatory == true && (p.value == '' || p.value == null)) {
                reject('Missing value for ' + p.label);
                return;
              }
              if (p.type == 'month' && p.value.split('-').length == 2)
                p.value = p.value + '-01';
              data[p.paramname] = p.value;
            }
        }
        data["PagingArgs"] = pagingData;
        
        if (self.userService.selectedAirlinesIds().length > 1) {
          reject('Please select a specific airline from the user settings in order to see this report.');
          return;
        }
        data['name'] = reportData.name;
        data['AirlineID'] = self.userService.selectedAirlinesIds()[0];
          data['airlinesId'] = self.userService.selectedAirlinesIdAsString();
          data['configurationsId'] = self.userService.selectedConfigsIdsAsString();
          data['timeZoneId'] = store.state.reportState?.selectedTimeZone?.id;
          //console.log('loadReportData', data)
          //let var_url = reportData.customReportType == 'PassengerDetails' ? "GetTableData" : "GetReportData";
          const url = baseURL + "GetPassengerList";
        
        const config = {
          transformResponse: (res: string) => {
            var reISO = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*))(?:Z|(\+|-)([\d|:]*))?$/;
            var reMsAjax = /^\/Date\((d|-|.*)\)[\/|\\]$/;

            let dateReviver = function (key: any, value: any) {
              // plug this regex into regex101.com to understand how it works
              // matches 2019-06-20T12:29:43.288 (with milliseconds) and 2019-06-20T12:29:43 (without milliseconds)
              var dateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{1,}|)$/;
              if (typeof value === "string" && dateFormat.test(value)) {
                let rv = new Date(value + 'Z');
                return rv;
              }

              return value;
            };
            try {
              return JSON.parse(res, dateReviver);
            } catch {
              console.log('error transforming string', res);
            }
          },
          cancelToken: request.token
        };

        axiosRetry(axios, { retries: AxiosRetriesCount });
       
        axios
          .post(url, data, config)
          .then(
            response => {
              let udpReport = new TableReport(reportData, 
                response.data.tableData,
                response.data.tableHeaders
              );
              udpReport.totalRows = response.data.totalRows;
              //udpReport.setPageSize(10);
              resolve(udpReport);
            },
            err => { reject(err); }
          )
          .catch(function (error: any) {
            reject(error);
          });

      }
      catch(error) {
        reject(error);
      };
    });
  }
  async loadReportData(reportData: IReportData, chart: Highcharts.Chart | null): Promise<Report> {
    let self = this;
    return new Promise(function (resolve, reject) {
      try {
        let data: any = {};
        if (reportData.parameters != null) {
          for (let i = 0; i < reportData.parameters.length; i++) {
            let p = reportData.parameters[i];
            if (p.mandatory==true &&(p.value == '' || p.value == null)) {
              reject('Missing value for ' + p.label);
              return;
            }
            if (p.type == 'month' && p.value.split('-').length == 2)
              p.value = p.value + '-01';
            data[p.paramname] = p.value;
          }
        }
        data['name'] = reportData.name;
        data['airlinesId'] = self.userService.selectedAirlinesIdAsString();
        data['configurationsId'] = self.userService.selectedConfigsIdsAsString();
        data['timeZoneId'] = store.state.reportState?.selectedTimeZone?.id;
        const url = baseURL + "GetReportData";

        const config = {
          transformResponse: (res: string) => {
            var reISO = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*))(?:Z|(\+|-)([\d|:]*))?$/;
            var reMsAjax = /^\/Date\((d|-|.*)\)[\/|\\]$/;

            let dateReviver = function (key: any, value: any) {
              // plug this regex into regex101.com to understand how it works
              // matches 2019-06-20T12:29:43.288 (with milliseconds) and 2019-06-20T12:29:43 (without milliseconds)
              var dateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{1,}|)$/;
              if (typeof value === "string" && dateFormat.test(value)) {
                let rv = new Date(value + 'Z');
                return rv;
              }

              return value;
            };
            try {
              return JSON.parse(res, dateReviver);
            } catch {
              console.log('error transforming string', res);
            }
          }
        };

        axiosRetry(axios, { retries: AxiosRetriesCount });
        axios
          .post(url, data, config)
          .then(
            response => {
              if (chart != null) {
                if (reportData.chartOptions.xAxis?.type === 'datetime') {
                  let series = response.data.series;
                  for (let serieid = 0; serieid < series.length; serieid++) {
                    let data = series[serieid].data;
                    if (Array.isArray(data[0])) {
                      for (let idata = 0; idata < data.length; idata++) {
                        data[idata][0] = (data[idata][0]).getTime();
                      }
                    }
                  }
                }
              }
              let udpReport = new Report(reportData, chart,
                response.data.series, response.data.tableData,
                response.data.tableHeaders,
                response.data.chartCategories === undefined ? null : response.data.chartCategories);

              if (reportData.chartDefaultButtonPress != null && reportData.chartButtons !== null) {
                let bt = reportData.chartButtons[reportData.chartDefaultButtonPress];

                if (reportData.limitRows != null)
                  udpReport.setPageSize(reportData.limitRows);
                else
                  udpReport.setPageSize(10);
                udpReport.sortByColumn(bt.column);
              }
              else if (reportData.limitRows != null) {
                udpReport.setPageSize(reportData.limitRows);
              }
              else if (reportData.chartPagination == true) {
                udpReport.setPageSize(10);
              }
              if (chart != null) {
                udpReport.updateChartContent();
              }
              resolve(udpReport);
            },
            err => { reject(err); }
          )
          .catch(function (error: any) {
            reject(error);
          });
      }
      catch(error) {
        reject(error);
      };
    });
  }

  async loadGroupedMultiselect(multiselectname:string): Promise<any> {
    let self = this;
    return new Promise(function (resolve, reject) {
      try {
        let data: any = {};
        data['multiselectname'] = multiselectname;

        const url = baseURL + "GetGroupedMultiselect";

        axiosRetry(axios, { retries: AxiosRetriesCount });
        axios
          .post(url, data)
          .then(
            response => {
              resolve(response.data);
            },
            err => { reject(err); }
          )
          .catch(function (error: any) {
            reject(error);
          });
      }
      catch(error) {
        reject(error);
      };
    });
  }
  async loadLocation(selectname: string, query: string): Promise<any> {
    let self = this;
    return new Promise(function (resolve, reject) {
      try {
        let data: any = {};
        data['Selectname'] = selectname;
        data['query'] = query;

        const url = baseURL + "GetLocationSelect";

        axiosRetry(axios, { retries: AxiosRetriesCount });
        axios
          .post(url, data)
          .then(
            response => {
              resolve(response.data);
            },
            err => { reject(err); }
          )
          .catch(function (error: any) {
            console.log('error',error)
            reject(error);
          });
      }
      catch(error) {
        console.log('error', error)
        reject(error);
      };
    });
  }

  async loadSelect(selectname: string): Promise<any> {
    let self = this;
    return new Promise(function (resolve, reject) {
      try {
        let data: any = {};
        data['selectname'] = selectname;

        const url = baseURL + "GetSelect";

        axiosRetry(axios, { retries: AxiosRetriesCount });
        axios
          .post(url, data)
          .then(
            response => {
              resolve(response.data);
            },
            err => { reject(err); }
          )
          .catch(function (error: any) {
            reject(error);
          });
      }
      catch(error) {
        reject(error);
      };
    });
  }

  loadReportFromStore(reportId: number): IReportData|null {
    const allReports = store.state.reportState?.allReports;
    if (allReports != null) {
      for (let i = 0; i < allReports.length; i++) {
        if (allReports[i].id == reportId) {
          return allReports[i];
        }
      }
    }
    return null;
  };
  showDialogChart(newReportData: IReportData) {
    store.dispatch("reportState/" + ReportActions.ShowDialogReport, newReportData);
  }
  tableCustomSort(items: any, index: any, isDesc: any): any {
    items.sort((a: any, b: any) => {
      let ta = a['isTotal'];
      let tb = b['isTotal'];
      if (ta !== undefined) {
        if (ta != tb)
          return ta - tb;
      }

      ta = a[index];
      tb = b[index];

      let bisDesc = isDesc;
      if (typeof bisDesc !== 'boolean')
        bisDesc = isDesc[0];

      if (typeof ta === 'number' && typeof tb === 'number') {
        if (!bisDesc)
          return ta - tb;
        else
          return tb - ta;
      }

      if (ta == undefined && tb == undefined)
        return 0;
      if (ta == undefined)
        return bisDesc;
      if (tb == undefined)
        return !bisDesc;

      if (typeof ta.getMonth === 'function') {
        if (!bisDesc)
          return ta.getTime() - tb.getTime();
        else
          return tb.getTime() - ta.getTime();
      }
      if (!bisDesc)
        return ta.toLowerCase().localeCompare(tb.toLowerCase());
      else
        return tb.toLowerCase().localeCompare(ta.toLowerCase());
    });
    return items;
  }
  TableItemFormat(item: any, header: any) : any{
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    let value = item[header.value];
    if (value == null)
      return value;
    switch (header.format) {
      case 'locale': return value.toLocaleString();
      case 'fixed2': return value.toFixed(2);
      case 'day':
        if (typeof value.getMonth === 'function')
          return monthNames[value.getUTCMonth()] + ' ' + value.getUTCDate();
        return value;
      case 'month':
        if (typeof value.getMonth === 'function')
          return monthNames[value.getUTCMonth()] + ' ' + value.getUTCFullYear();
        return value;
      case 'hour':
        if (typeof value.getMonth === 'function')
          return monthNames[value.getUTCMonth()] + ' ' + value.getUTCDate() + ', ' + value.getUTCHours() + ':00';
        return value;
      default: return value;
    }
  }
}
