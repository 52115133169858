














import { defineComponent } from '@vue/composition-api';
import { reactive } from "@vue/composition-api";
//import Textfield from 'vue-textfield'
import { IParameterData } from "@/store/stateDefinition/reportState"
//import { ReportService } from '@/services/reportService'

export default defineComponent({
  name:'TextboxComponent',
  props: {
    param: {
      type: Object,
      required: true
    }
  },
  components: {
    //Textfield
  },
  setup(props: any) {
    const parameterData: IParameterData = props.param;
    //const reportService = new ReportService();

    const state = reactive({
      text:''
    });

    //reportService.loadSelect(parameterData.name).then((t) => {
    //  state.options = t;
    //});

    const save = (text: any) => {
      parameterData.value = text;
    }
    return {
      state,
      save,
      parameterData
    }
  },
});
