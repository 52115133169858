
































































































import { defineComponent, reactive, computed, watch } from "@vue/composition-api";
import { UserService } from '@/services/userService'
import { UserConfiguration, UserRole } from '@/store/stateDefinition/userState'

export default defineComponent({
  name: 'UserComponent',
  props: {
    userId: {
      type: String,
      required: true
    },
    surname: {
      type: String,
      required: false
    },
    firstname: {
      type: String,
      required: false
    },
    email: {
      type: String,
      required: false
    },
    back: {
      type: String,
      required: false
    },
    confirmDelete: {
      type: String,
      required: false
    },
    confirmEdit: {
      type: String,
      required: false
    },
    action: {
      type: String,
      required: true
    }
  },
  setup(props: any) {
    const userGuid = props.userId;
    const operation = props.action;
    let email = props.email ?? '';
    if (email.length == 0)
      email = null;
    const surname = props.surname ?? '';
    const firstname = props.firstname ?? '';
    let userName = null;
    if (surname.length > 0 && firstname.length > 0)
      userName = firstname + ' ' + surname;
    const back = props.back ?? '';
    const confirmDelete = props.confirmDelete ?? '';
    const confirmEdit = props.confirmEdit ?? '';

    const userService = new UserService();
    console.log('props', props)
    userService.getUserConfiguration(userGuid, userName, email)
      .then(t => {
        console.log(t);
        state.userConfiguration = t;
        state.roles = t.userRoles.filter(t => t.majorRole == true);
        state.selectedRoles = t.userRoles.filter(t => t.majorRole == true && t.selected == true)[0];
        state.minorRoles = t.userRoles.filter(t => t.majorRole == false);
        state.selectedMinorRoles = t.userRoles.filter(t => t.majorRole == false && t.selected == true);
        let airlinesName: string[] = []
        let airlinesSelection: any = {}
        t.airlineConfiguration.forEach(a => {
          let idx = airlinesName.indexOf(a.airline);
          if (idx < 0) {
            airlinesName.push(a.airline);
            airlinesSelection[a.airline] = [];
          }
          airlinesSelection[a.airline].push(a);
        })
        state.airlinesName = airlinesName;
        state.airlinesSelection = airlinesSelection;
        state.isError = false;
        state.ready = true;
      })
      .catch(t => {
        state.isError = true;
        if (t.isError != undefined) {
          state.errorMessage = t.errorMessage;
        }
        else {
          state.errorMessage = 'Error loading the user data';
        }
        state.ready = true;
      });
    const goback = () => {
      //console.log('back', [state.saved, state.back, confirmEdit]);
      //return;
      if (state.saved && confirmEdit.length > 0) {
        window.location.href = confirmEdit;
      }
      else {
        window.location.href = state.back;
      }
    }
    const save = () => {
      state.busy = true;
      const uc = state.userConfiguration as UserConfiguration;
      const selectedRole = state.selectedRoles as UserRole;
      const selectedMinorRoles = state.selectedMinorRoles as UserRole[];
      console.log('loop')
      for (let i = 0; i < uc.userRoles.length; i++) {
        const role = uc.userRoles[i];
        if (selectedRole == role || selectedMinorRoles.indexOf(role) >= 0) {
          role.selected = true;
        }
        else {
          role.selected = false;
        }
      }
      console.log('saving', uc);
      const userService = new UserService();
      userService.saveUserConfiguration(uc)
        .then(t => {
          state.topBarMsg = 'User saved';
          state.topBarColor = 'green';
          state.showTopBar = true;
          state.busy = false;
          state.saved = true;
        })
        .catch(t => {
          state.topBarMsg = 'Error saving the user';
          state.topBarColor = 'red';
          state.showTopBar = true;
          state.busy = false;
        });
    }
    const onDelete = () => {
      state.showDeleteDialog = true;
    }
    const onDeleteConfirmed = () => {
      state.busy = true;
      const uc = state.userConfiguration as UserConfiguration;
      const userService = new UserService();
      userService.deleteUser(uc.userId)
        .then(t => {
          state.topBarMsg = 'User deleted';
          state.topBarColor = 'green';
          state.showTopBar = true;
          state.busy = false;
          state.showDeleteDialog = false;
          if (confirmDelete.length > 0) {
            window.location.href = confirmDelete;
          }
        })
        .catch(t => {
          state.topBarMsg = 'Error deleting the user';
          state.topBarColor = 'red';
          state.showTopBar = true;
          state.busy = false;
        });
    }
    const state = reactive({
      userConfiguration: {},
      roles: {},
      selectedRoles: {},
      minorRoles: {},
      selectedMinorRoles: {},
      airlinesName: {},
      airlinesSelection: {},
      isError: false,
      errorMessage: '',
      ready: false,
      showTopBar: false,
      topBarMsg: 'Error',
      topBarColor: 'red',
      busy: false,
      showDeleteDialog: false,
      operation,
      readonly: operation == 'delete',
      back,
      saved: false
    });
    return {
      state,
      save,
      onDelete,
      onDeleteConfirmed,
      goback
    }
  }
});
