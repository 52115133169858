








  //import worldMap from '@highcharts/map-collection/custom/world-highres3.geo.json'
  import worldMap from '@highcharts/map-collection/custom/world.geo.json'
  import { reactive } from "@vue/composition-api";
  import { defineComponent } from '@vue/composition-api';
  import { IReportData } from "@/store/stateDefinition/reportState"
  import { ReportService } from '@/services/reportService'
  import { Report } from '../services/report';
  import { SeriesOptionsType } from 'highcharts';
import { ChartService } from '../services/chartService';

  export default defineComponent({
    name: 'ReportComponent',
    props: {
      report: {
        type: Object,
        required: true
      }
    },
    setup(props: any) {
      const mapoptions = {
        chart: {
          map: worldMap
        },
        title: {
          text: 'Flights'
        },
        mapNavigation: {
          enabled: true,
          buttonOptions: {
            alignTo: 'spacingBox'
          }
        },
        colorAxis: {
          min: 0
        },
        credits: {
          enabled: false
        },
        legend: {
          enabled: false
        },
        tooltip: {
          enabled: false,
          headerFormat: '<span style="font-size:10px">{series.name}</span><br/>',
          pointFormat: '{point.id}',
          footerFormat: '{formatPoint(point.id)}'
        },
        plotOptions: {
          series: {
            marker: {
              fillColor: '#FFFFFF',
              lineWidth: 2,
              lineColor: '#0000FF'
            }
          }
        }
      }
      const formatPoint = (id: string) => {
        return "aaa " + id;
      }
      const reportData: IReportData = props.report;
      reportData.chartOptions = mapoptions;

      Report.setDateParameters(reportData);

      const state = reactive({
        report: {},
        loaded: false,
      });
      const reportService = new ReportService();

      const afterChartCreate = (chart: Highcharts.Chart) => {
        reportService.loadReportData(reportData, chart).then((nreport) => {
          state.report = nreport;
          state.loaded = true;         
        })
      }
      return {
        state,
        afterChartCreate,
        reportService,
        formatPoint,
      }
    }
  });
