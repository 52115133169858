























import { defineComponent } from '@vue/composition-api';
import { reactive } from "@vue/composition-api";
import { IParameterData } from "@/store/stateDefinition/reportState"
export default defineComponent({
  props: {
    param: {
      type: Object,
      required: true
    }
  },
  setup(props: any) {
    const parameterData: IParameterData = props.param;
    const state = reactive({
      date: parameterData.value, 
      menu: false,
    });

    const save = (date: any) => {
      if (date.split('-').length == 2)
        date = date + '-01';
      parameterData.value = date;
    }
    return {
      state,
      save,
      parameterData
    }
  },
});
