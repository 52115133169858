






import router from '@/router'
import UserComponent from '@/components/UserComponent.vue'

export default {
  components: { UserComponent },
  setup() {
    const userGuid = router.currentRoute.params['userGuid'];
    const surname: any = decodeURIComponent(router.currentRoute.params['surname']);
    const firstname: any = decodeURIComponent(router.currentRoute.params['firstname']);
    const email: any = decodeURIComponent(router.currentRoute.params['email']);
    const back: any = decodeURIComponent(router.currentRoute.params['back']);
    const confirmDelete: any = decodeURIComponent(router.currentRoute.params['confirmDelete']);
    return {
      userGuid,
      back,
      confirmDelete,
      surname,
      firstname,
      email
    }
  }
}
