import { ChartUpdateArgs, ChartOptions } from 'highcharts-vue'

export enum eCookiePolicy {
  notSet,
  declined,
  accepted
}

export type ReportState = {
  reportHomeResult: ReportHomeResultState | null;
  reportAirlines: ReportAirlineState[] ;
  selectedAirlines: ReportAirlineState[] ;
  allAirlineConfigs: AirlineConfigState[];
  timeZones: ReportTimeZone[];
  selectedTimeZone: ReportTimeZone | null;
  airlineConfigsNames: string[];
  selectedAirlineConfigsNames: string[];
  allReports: IReportData[];
  userSelectionChange: number;
  userConfirmedSelectionChange: number,
  ready: boolean,
  readyDate: number,
  selectedReportId: number,
  selectedReportUrl: string,
  errorBarShow: boolean,
  errorBarDate: number,
  errorBarText: string,
  dialogReport: IReportData | null,
  dialogReportKey: number,
  cookiePolicy: eCookiePolicy
}

export type ReportHomeResultState = {
    numberOfPassengers: number;
    latencyPerPassenger: number;
    redIncidents: number;
    amberIncidents: number;
    greenIncidents: number;
}

export type ReportTimeZone = {
  id: number,
  name: string,
  standartName: string
}

export type ReportAirlineState = {
  airlineId: number,
  iata: string
}

export type AirlineConfigState = {
  airlineId: number,
  configId: number,
  name: string
}

export type AirlineConfigUniqueNameState = {
  id: number,
  name: string
}

export interface IParameterData {
  readonly id?: number
  readonly paramname: string
  readonly name: string
  readonly label: string
  readonly options: any[] | any | null
  value: string
  readonly type: string
  readonly mandatory:boolean | null | any
}

export interface IChartButton {
  readonly text: string
  readonly type: string
  readonly column: string
}

export interface IParameterMapping {
  readonly originName: string
  readonly destinationName: string
  readonly pointSelectionKey: string
}

export interface IComposedTitle {
  readonly paramValue: string | null
  readonly text: string | null
}

export interface ISerieClick {
  readonly action: string
  readonly reportId: string
  readonly parametersMapping: IParameterMapping[],
  readonly composedTitle: IComposedTitle[] | null
}

export interface IReportData {
  readonly id?: number
  readonly reportUrl: string
  dialogTitle: string | null|any
  readonly hideFromList: boolean|null|any
  readonly customReportType: string | null
  readonly name: string
  readonly title: string
  readonly airline:string
  readonly isFullReport: boolean
  readonly isMap: boolean|null|any
  readonly fullReportId: number
  readonly limitRows: number | any
  readonly exportChartData: boolean | null
  chartOptions: ChartOptions | any
  readonly secondaryCharts: [ChartOptions] | any

  readonly updateArgs: ChartUpdateArgs | any
  readonly chartDefaultButtonPress: number | null
  readonly resizeChart: boolean | null | any

  readonly parameters: IParameterData[] | null
  readonly chartButtons: IChartButton[] | any
  readonly chartPagination: boolean | any
  readonly serieClick: ISerieClick | any
}
