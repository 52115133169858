import Vue from 'vue';
import proj4 from './plugins/proj4'

import Highcharts from 'highcharts';
import Stock from "highcharts/modules/stock";
import Maps from "highcharts/modules/map";
import HighchartsVue from 'highcharts-vue';

Maps(Highcharts);
Stock(Highcharts);
Vue.use(HighchartsVue);
((Highcharts as any).win as any).proj4 = proj4

Highcharts.setOptions({
  chart: {
    animation: false,
  },
  title: {
    style: {
      "color": "#0a406d",
      "fontSize": "18px",
      "font": "'Avenir', Helvetica, Arial, sans- serif"
    }
  },
  plotOptions: {
    series: {
      animation: false
    }
  },
  drilldown: {
    animation: {
      duration: 0
    }
  }
})

import VueCompositionAPI from '@vue/composition-api'
Vue.use(VueCompositionAPI)

import VueCookies from "vue-cookies-ts"
Vue.use(VueCookies)

import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

Vue.config.productionTip = false;

import VueAppInsights from '@/plugins/applicationInsights'
let appInsightsId: string = (window as any).VueConfig?.VUE_ApplicationInsights
if (appInsightsId != null && appInsightsId.length > 0) {
  Vue.use(VueAppInsights, {
    id: (window as any).VueConfig.VUE_ApplicationInsights,
    router,
    baseName: 'VUE',
    trackInitialPageView: true,
    disableTelemetry: true
  })
}
import VueWait from 'vue-wait'
Vue.use(VueWait)
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
  wait: new VueWait({
    // Defaults values are following:
    useVuex: false,              // Uses Vuex to manage wait state
    vuexModuleName: 'wait',      // Vuex module name

    registerComponent: true,     // Registers `v-wait` component
    componentName: 'v-wait',     // <v-wait> component name, you can set `my-loader` etc.

    registerDirective: true,     // Registers `v-wait` directive
    directiveName: 'wait',       // <span v-wait /> directive name, you can set `my-loader` etc.

  })
}).$mount('#app')
