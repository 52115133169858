

































import Navigation from '@/components/Navigation.vue'
import { reactive, ref, computed } from "@vue/composition-api";

import store from "@/store/index";
import { ReportAirlineState, AirlineConfigState, IReportData } from "@/store/stateDefinition/reportState"
import { ReportService } from '@/services/reportService'
import ReportComponent from '@/components/ReportComponent.vue'
import SummaryComponent from '@/components/SummaryComponent.vue'
import ReportMapComponent from '@/components/ReportMapComponent.vue'

export default {
  components: { Navigation, ReportComponent, SummaryComponent, ReportMapComponent },
  setup() {
    const state = reactive({
    });
    const reportService = new ReportService();

    const summaryReports = computed((): IReportData[] => store.getters["reportState/summaryReportsGetter"])
    const allReports = computed((): IReportData[] => store.getters["reportState/dashboardReportsGetter"])
    const allMaps = computed((): IReportData[] => store.getters["reportState/dashboardMapReportsGetter"])
    const ready = computed((): boolean => store.getters["reportState/readygetter"])
    const readyDate = computed((): number => store.getters["reportState/readyDate"]);
    const userSelectionChange = computed((): number => store.getters["reportState/userSelectionChange"])

    return {
      reportService,
      state,
      ready,
      readyDate,
      allReports,
      allMaps,
      summaryReports,
      userSelectionChange,
    }
  }
}
