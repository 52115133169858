import store  from '@/store'
import { vuexOidcProcessSignInCallback } from 'vuex-oidc'
import { UserConfiguration } from '@/store/stateDefinition/userState'

import axios from "@/config/axiosSetup"
import axiosRetry from 'axios-retry';

const baseURL = (window as any).VueConfig.VUE_APP_ROOT_API + "user/";
const AxiosRetriesCount = 5;

export class UserService {
  //getToken(): Promise<string> {
  //  let self = this;
  //  return new Promise(function (resolve, reject) {
  //    let atk = store.state.oidcStore.access_token;
  //    if (atk != null) {
  //      resolve(atk);
  //    }
  //    else {
  //      reject('Invalid access_token');

  //      //let mng = new oidc.UserManager(oidcSettings);
  //      //mng.g
  //      //vuexOidcProcessSignInCallback(oidcSettings).then(() => {
  //      //  atk = store.state.oidcStore.access_token;
  //      //  resolve(atk);
  //      //})
  //      //.catch(function (error: any) {
  //      //  console.log("getToken error", error);
  //      //  reject(error);
  //      //});
  //    }
  //  });
  //}
  allAirlinesIds(): number[] {
    let airlines = store.state.reportState?.reportAirlines;
    if (airlines === undefined)
      return []
    return airlines.filter(t=>t.airlineId>0).map(t => t.airlineId)
  }
  isCTRIP(): boolean {
    let airlines = store.state.reportState?.selectedAirlines;
    if (airlines === undefined || airlines.length == 0 || (airlines.length == 1 && airlines[0].airlineId == 0)) {
      airlines = store.state.reportState?.reportAirlines;
      
    }
    if (airlines === undefined || airlines.length == 0) {
      return false;
    }
      if (airlines.length > 1) {
        return false;
    }
      //'C-Trip'
    if (airlines[0]?.iata == 'C-Trip' || airlines[0]?.iata == 'CTRP') {
        return true;
      }
    
      return false;


  }
  selectedAirlinesIds(): number[] {
    let airlines = store.state.reportState?.selectedAirlines;

    if (airlines === undefined || airlines.length == 0 || (airlines.length==1 && airlines[0].airlineId == 0))
      return this.allAirlinesIds()
    return airlines.filter(t => t.airlineId > 0).map(t=>t.airlineId)
  }
  selectedAirlinesIdAsString(): string {
    let ids = this.selectedAirlinesIds();

    let selectedIds = "";
    ids.map(id => selectedIds += selectedIds.length == 0 ? id : "," + id);
    return selectedIds;
  }
  allConfigsId(): number[] {
    let configs = store.state.reportState?.allAirlineConfigs;
    if (configs === undefined)
      return []
    return configs.filter(t => t.configId > 0).map(t => t.configId)
  }
  selectedConfigsIds(): number[] {
    if (store.state.reportState == null)
      return [];
    let selectedConfigs = store.state.reportState.selectedAirlineConfigsNames;
    let allConfigs = store.state.reportState.allAirlineConfigs;
    if (selectedConfigs === undefined || selectedConfigs.length == 0 || (selectedConfigs.length == 1 && selectedConfigs[0] == 'All'))
      return this.allConfigsId()
    let id: number[] = [];
    for (let i = 0; i < allConfigs.length; i++) {
      let c = allConfigs[i];
      let nameInList = selectedConfigs.find(t => t == c.name) !== undefined;
      if (nameInList)
        id.push(c.configId);
    }
    return id;
  }
  selectedConfigsIdsAsString(): string {
    let ids = this.selectedConfigsIds();
    let selectedIds = "";
    ids.map(id => selectedIds += selectedIds.length == 0 ? id : "," + id);
    return selectedIds;
  }

  async getUserConfiguration(userGuid: any, userName:string|null, email:string|null): Promise<UserConfiguration> {
    let self = this;
    return new Promise(function (resolve, reject) {
      try {
        let data: any = {};
        data['userGuid'] = userGuid;
        if(userName!==null)
          data['userName'] = userName;
        if(email !== null)
          data['email'] = email;
        const url = baseURL + "GetUserConfiguration";

        const config = {
          transformResponse: (res: string) => {
            var reISO = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*))(?:Z|(\+|-)([\d|:]*))?$/;
            var reMsAjax = /^\/Date\((d|-|.*)\)[\/|\\]$/;

            let dateReviver = function (key: any, value: any) {
              // plug this regex into regex101.com to understand how it works
              // matches 2019-06-20T12:29:43.288 (with milliseconds) and 2019-06-20T12:29:43 (without milliseconds)
              var dateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{1,}|)$/;
              if (typeof value === "string" && dateFormat.test(value)) {
                let rv = new Date(value + 'Z');
                return rv;
              }

              return value;
            };
            try {
              return JSON.parse(res, dateReviver);
            } catch {
              console.log('error transforming string', res);
            }
          }
        };

        axiosRetry(axios, { retries: AxiosRetriesCount });
        axios
          .post(url, data, config)
          .then(
            response => {
              var responseData = response.data as UserConfiguration;
              console.log(responseData);
              resolve(responseData);
            },
            err => {
              console.log("userservice error", err);
              if (err.response?.data !== undefined) {
                reject(err.response?.data);
              }
              else {
                reject(err);
              }
            }
          )
          .catch(function (error: any) {
            reject(error);
          });
      }
      catch(error) {
        reject(error);
      };
    });

  }

  async saveUserConfiguration(configuraton: UserConfiguration): Promise<void> {
    let self = this;
    return new Promise(function (resolve, reject) {
      try {
        const data = configuraton;
        const url = baseURL + "SaveUserConfiguration";
        console.log('save data', data)

        axiosRetry(axios, { retries: AxiosRetriesCount });
        axios
          .post(url, data)
          .then(
            response => {
              resolve();
            },
            err => {
              reject(err);
            }
          )
          .catch(function (error: any) {
            reject(error);
          });
      }
      catch(error) {
        reject(error);
      };
    });
  }

  async deleteUser(userId: string): Promise<void> {
    let self = this;
    return new Promise(function (resolve, reject) {
      try {
        const data = { userGuid: userId };
        const url = baseURL + "DeleteUser";

        axiosRetry(axios, { retries: AxiosRetriesCount });
        axios
          .post(url, data)
          .then(
            response => {
              resolve();
            },
            err => {
              reject(err);
            }
          )
          .catch(function (error: any) {
            reject(error);
          });
      }
      catch(error) {
        reject(error);
      };
    });
  }
}
