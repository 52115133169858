<template>
  <div>
  </div>
</template>

<script>
  import { onMounted } from "@vue/composition-api";
  import { vuexOidcProcessSilentSignInCallback } from 'vuex-oidc'
  export default {
    name: 'oidc-callback-silent',
    setup() {
      onMounted(() => vuexOidcProcessSilentSignInCallback());
    }
  }
</script>
