import Vue from 'vue'
import VueRouter from 'vue-router'
import Report from '@/views/Report.vue'
import Dashboard from '@/views/Dashboard.vue'
import { vuexOidcCreateRouterMiddleware } from 'vuex-oidc'
import OidcCallback from '@/views/OidcCallback.vue'
import OidcCallbackSilent from '@/views/OidcCallbackSilent.vue'
import OidcPopupCallback from '@/views/OidcPopupCallback.vue'
import OidcCallbackError from '@/views/OidcCallbackError.vue'
import User from '@/views/User.vue'
import UserDelete from '@/views/UserDelete.vue'
import store from '@/store'

Vue.use(VueRouter)

const routes:any = [
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard
  },
  {
    path: '/reports/:reportUrl',
    name: 'Report',
    component: Report
  },
  {
    path: '/user/upsert/:userGuid/:email/:surname/:firstname/:back/:confirmEdit',
    name: 'User',
    component: User
  }, 
  {
    path: '/user/upsert/:userGuid/:email/:surname/:firstname/:back',
    name: 'User',
    component: User
  },
  {
    path: '/user/delete/:userGuid/:email/:surname/:firstname/:back/:confirmDelete',
    name: 'UserDelete',
    component: UserDelete
  },
  {
    path: '/oidc-callback', // Needs to match redirectUri in you oidcSettings
    name: 'oidcCallback',
    component: OidcCallback
  },
  {
    path: '/oidc-callback-silent', // Needs to match redirectUri in you oidcSettings
    name: 'oidc-callback-silent',
    component: OidcCallbackSilent
  },
  {
    path: '/oidc-popup-callback', // Needs to match popupRedirectUri in you oidcSettings
    name: 'oidcPopupCallback',
    component: OidcPopupCallback
  },
  {
    path: '/oidc-callback-error', // Needs to match redirect_uri in you oidcSettings
    name: 'oidcCallbackError',
    component: OidcCallbackError,
    meta: {
      isPublic: true
    }
  },
]
const router:any = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(vuexOidcCreateRouterMiddleware(store, 'oidcStore'))

export default router
