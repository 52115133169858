















import { defineComponent } from '@vue/composition-api';
import { reactive } from "@vue/composition-api";
import Multiselect from 'vue-multiselect'
import { IParameterData } from "@/store/stateDefinition/reportState"
import { ReportService } from '@/services/reportService'

export default defineComponent({
  props: {
    param: {
      type: Object,
      required: true
    }
  },
  components: {
    Multiselect
  },
  setup(props: any) {
    const parameterData: IParameterData = props.param;
    const reportService = new ReportService();

    const state = reactive({
      options: [],
      selection: []
    });

    if (parameterData.options != null)
    {
      state.options = parameterData.options as any;
      state.selection = state.options[0];
      parameterData.value = (state.selection as any).name;
    }
    else {
      reportService.loadSelect(parameterData.name).then((t) => {
        state.options = t;
        state.selection = t[0];
        parameterData.value = (state.selection as any).name;
      });
    }

    const save = (item: any) => {
      parameterData.value = item.name;
    }
    return {
      state,
      save,
      parameterData
    }
  },
});
