




































































































































































import Vue from 'vue';
import { reactive, ref, computed, watch, onMounted } from "@vue/composition-api";
import store from "@/store/index";
import { ReportAirlineState, AirlineConfigState, IReportData, ReportTimeZone, eCookiePolicy } from "@/store/stateDefinition/reportState"
import { ReportService } from '@/services/reportService'
import { UserService } from '@/services/userService'
import { ReportActions } from '@/store/actions/reportActions'

import router from '@/router'
import CookieLaw from "vue-cookie-law";

export default {
  name: 'Navigation',
  components: {
    CookieLaw
  },
  setup() {
    //cookie-law: use a wrong domain so it cannot set a cookie, this is because "ref" is not working on vue2 composition api
    //and the control cannot be resetted using the link "manage cookie preferences"
    const cookieOptions = { "domain": "wrongdomain", "path": "/", "expires": "1Y" }; 
    const cookiePolicy = computed((): eCookiePolicy => store.getters["reportState/cookiePolicy"]);

    const state = reactive({
      drawerLeft: false,
      drawerRight: false
    });
    const userService = new UserService();
    const reportService = new ReportService();
    const reportClick = (id: number) => {
      store.dispatch("reportState/" + ReportActions.SetSelectedReportId, id);
      if (id == 0)
        router.push('/');
      else
        router.push(reportService.reportUrlReportId(id));
    }
    const logOff = () => {
      store.dispatch("oidcStore/signOutOidc");
    }
    const selectedAirlines = computed(
      {
        get: () => store.getters["reportState/selectedAirlinesGetter"],
        set: (selection: ReportAirlineState[]) => store.dispatch("reportState/" + ReportActions.SetSelectedAirlines, selection)
      });
    const reportAirlines = computed((): ReportAirlineState[] | null => store.getters["reportState/reportAirlinesGetter"]);
    const joinedAirlines = computed((): string => reportService.joinedAirlines(selectedAirlines.value));

    const selectedConfigs = computed({
      get: () => store.getters["reportState/selectedAirlineConfigsGetter"],
      set: (selection: string[]) => store.dispatch("reportState/" + ReportActions.SetSelectedConfigs, selection)
    })
    const airlineConfigs = computed((): string[] | null => store.getters["reportState/airlineConfigsGetter"]);
    const joinedConfigs = computed((): string => reportService.joinedConfigs(selectedConfigs.value))

    const selectedTimeZone = computed({
      get: () => store.getters["reportState/selectedTimeZone"],
      set: (selection: ReportTimeZone) => {
        store.dispatch("reportState/" + ReportActions.SetSelectedTimeZone, selection);
      }
    })
    const timeZones = computed((): ReportTimeZone[] => store.getters["reportState/timeZones"]);

    const allReports = computed((): IReportData[] => store.getters["reportState/fullReportsGetter"])
    const ctripReport = computed((): IReportData[] => store.getters["reportState/ctripReportGetter"])
    //const airlines = computed((): ReportAirlineState[] => store.getters["reportState/reportAirlinesGetter"])
    const showReports = computed((): IReportData[] => buildReportsList())
    
    const buildReportsList = (): IReportData[] =>
    {
      let r = allReports.value.filter(rr => rr.customReportType != "CTRP"
        || (rr.customReportType == "CTRP" && userService.isCTRIP() == true))
     
        return r
    }
    const ready = computed((): boolean => store.getters["reportState/readygetter"])

    const setDrawerLeft = () => {
      state.drawerLeft = true;
    }
    const setDrawerRight = () => {
      state.drawerRight = true;
    }
    const revoke = () => {
      store.dispatch("reportState/" + ReportActions.SaveCookiePolicy, eCookiePolicy.notSet);
      (Vue as any).appInsights.config.disableTelemetry = true;
    }
    const accept = () => {
      store.dispatch("reportState/" + ReportActions.SaveCookiePolicy, eCookiePolicy.accepted);
      (Vue as any).appInsights.config.disableTelemetry = false;
    }
    const decline = () => {
      store.dispatch("reportState/" + ReportActions.SaveCookiePolicy, eCookiePolicy.declined);
      (Vue as any).appInsights.config.disableTelemetry = true;
    }
    watch(state, (newValue, oldValue) => {
      if (!newValue.drawerRight) {
        store.dispatch("reportState/" + ReportActions.SetConfirmedSelectionChanges);
      }
    })
    return {
      reportService,
      state,
      setDrawerLeft,
      setDrawerRight,
      selectedAirlines,
      selectedConfigs,
      joinedAirlines,
      joinedConfigs,
      reportAirlines,
      airlineConfigs,
      allReports,
      showReports,
      selectedTimeZone,
      timeZones,
      ready,
      reportClick,
      revoke,
      accept,
      decline,
      cookieOptions,
      cookiePolicy,
      logOff        
    }
  }
}
