











































































































































import { reactive, watch, computed, onMounted, onBeforeUpdate, onActivated } from "@vue/composition-api";
import { defineComponent } from '@vue/composition-api';
import { IReportData, IChartButton, IParameterData } from "@/store/stateDefinition/reportState"
import { ReportService } from '@/services/reportService'
import IParameterDataDatePicker from '@/components/IParameterDataDatePicker.vue'
import GroupedMultiSelect from '@/components/GroupedMultiSelect.vue'
import SingleSelect from '@/components/SingleSelect.vue'
import TextComponent from '@/components/TextComponent.vue'
import DrillDownDetails from '@/components/DrillDownDetails.vue'
//import TextboxComponent from '@/components/TextboxComponent.vue'
import AutocompleteComponent from '@/components/AutocompleteComponent.vue'
import { ExportService, ExportFormat } from '@/services/exportService'
import { TableReport } from '../services/tableReport';
import store from '../store';
import { ReportActions } from '@/store/actions/reportActions'
import { useWindowSize } from 'vue-window-size';
import Multiselect from 'vue-multiselect'
import { DrillDownReport } from '../services/drilldownReport'
import { DataOptions, DataTableHeader } from 'vuetify/types'
import { set } from 'vue/types/umd';
import staticAxios from 'axios'
import axios, { CancelTokenSource } from 'axios';
// @page-count="state.maxPages = $event"
export default defineComponent({
  name: 'TableReportComponent',
  components: {
    IParameterDataDatePicker, Multiselect, GroupedMultiSelect,
    SingleSelect, TextComponent, AutocompleteComponent, DrillDownDetails
  },
  props: {
    report: {
      type: Object,
      required: true
    },
    isDialog: {
      type: String,
      required: false
    }
  },
  methods: {
    expandedDetails(item: any, isExpanded: boolean) {
      //alert(JSON.stringify(item))
      return
    },
    handlePageChange(value: number) {
      //if (this.currentPage != value) {
      this.loadReportData();
      //}
      //this.currentPage = value;

    },

    handlePageSizeChange(size: string) {
      //this.rowsPerPage = size;

      this.currentPage = 1;
      this.maxPages = 0;
      this.loadReportData();


    },

    drillDown(item: any, headers: {
      expand: (value: boolean) => void,
      headers: DataTableHeader[],
      isExpanded: boolean,
      isMobile: boolean,
      isSelected: boolean,
      item: any,
      select: (value: boolean) => void
    }) {

      var index = this.state.expanded.indexOf(item)
      if (index >= 0) {
        this.state.expanded.splice(index, 1)
        this.state.drill = {}
        return
      }
      //this.state.expanded.splice(0, this.state.expanded.length)
      //this.reportService.loadDrillDown(item)
      //  .then(response => {
      //  /*this.state.expanded.push(item)*/
      //  //  console.log(JSON.stringify(response))
      //  //  console.log(response)
      //    this.state.drill = response

      //  })
      this.state.expanded.push(item)
    }
  },
  setup(props: any, { emit }) {
    const reportData: IReportData = reactive(props.report);
    const isDialog = props.isDialog != null && props.isDialog == "true";
    if (!isDialog) {
      TableReport.setDateParameters(reportData);
    }
    let activeReq: CancelTokenSource | null//= staticAxios.CancelToken.source();
    const state = reactive({
      report: {},
      // chart: {},
      loaded: false,
      busy: false,
      exportError: false,
      //options: {},
      maxPages: 0,
      mCurrentPageId: 1,
      mRowsPerPage: '5',
      rowsPerPageOptions: ['5', '10', '25', '50', '100', 'All'],
      chartHeight: 200,
      showReportDialog: false,
      reportDialog: {},
      reportDialogKey: 0,
      isDialog,
      vcardStyle: {
        maxHeight: "500px",
      },
      singleExpand: false,
      expanded: Array(),
      expandedDetails: Array(),
      expandedDoc: Array(),
      expandedSegments: Array(),
      drill: {},
      activeReq: staticAxios.CancelToken.source(),
      sortBy: 'Date',
      sortDesc: false


    });
    const reportService = new ReportService();
    const itemFormat = reportService.TableItemFormat;

    const userSelectionChange = computed((): boolean => store.getters["reportState/userSelectionChange"])
    watch(userSelectionChange, (newValue, oldValue) => {
      let report = state.report as TableReport;
      if (report.clearChartAndData !== undefined) {
        report.clearChartAndData();
        state.loaded = false;
      }
    })

    const options = {};
    //watch(options, () => {
    // // loadReportData()
    //})

    const sortBy = computed({
      get: () => state.sortBy,
      set: (value: any) => {
        if (value === undefined) {
          value='date'
        }
        if (typeof value === 'string') {
          state.sortBy = value
        }
        else {
          state.sortBy='date'
        }
      }
    })
    watch(sortBy, (newValue, oldValue) => {
      if (newValue != oldValue) {
        loadReportData()
      }
      
    })
    const sortDesc = computed({
      get: () => state.sortDesc,
      set: (value: any) => {
        if (value === undefined) {
          value=false
        }
        if (typeof value === "boolean") {
          state.sortDesc = value
        }
        else {
          state.sortDesc=false
        }
      }
    })
    watch(sortDesc, (newValue, oldValue) => {
      if (newValue != oldValue) {
        loadReportData()
      }
      
    })
    const { width, height } = useWindowSize();
    watch(height, (newValue, oldValue) => {
      setMaxHeight();
    })
    const setMaxHeight = () => {
      state.vcardStyle.maxHeight = (0.8 * height.value).toString() + 'px';
    }
    setMaxHeight();
    const maxPages = computed({
      get: ()=> state.maxPages,
      set: (newValue: number) => {
        state.maxPages = newValue;

      }
    });
    const currentPage = computed(
      {
        get: () => state.mCurrentPageId,
        set: (newId: number) => {
          if (state.loaded && newId <= state.maxPages && newId > 0 && newId != state.mCurrentPageId) {
            //let report = state.report as TableReport;
            state.mCurrentPageId = newId;
            
            //loadReportData();
          }
        }
      });
    const rowsPerPage = computed(
      {
        get: () => state.mRowsPerPage,
        set: (newValue: string) => {
          if (state.loaded && newValue != state.mRowsPerPage && newValue !== null)
          {
            //let report = state.report as TableReport;
            
            if (newValue == 'All') {
              state.mRowsPerPage = '9999999';
              //report.setPageSize(9999999);
            }
            else {
              state.mRowsPerPage = newValue;
              //report.setPageSize(parseInt(newValue));
            }
            //currentPage.value = 1;
            
            //state.maxPages = report.totalRows;
          }
        }
      });

    const loadTableHeaders = () => {
      reportService.loadTableHeaders(reportData)
        .then((nreport) => {
          state.report = nreport;
        })
        .catch((e) => {
          if (typeof e === "string") {
            store.dispatch("reportState/" + ReportActions.SetErrorBar, e)
          } else {
            store.dispatch("reportState/" + ReportActions.SetErrorBar, 'Error loading the report')
          }
          console.log(e);

        })
    }
    const cancelLoad = () => {
      console.log('cancel load');
      if (state.activeReq != null) {
        state.activeReq.cancel('Request was cancelled by user');
      }
      //state.activeReq = null;
    }
    const loadReportData = () => {
      state.loaded = false;
      state.busy = true;
      let pagingData: any = {};
      pagingData.Page = state.mCurrentPageId <= 0 ? 1 : state.mCurrentPageId ;
      pagingData.ItemsPerPage = state.mRowsPerPage;
      pagingData.SortColumn = state.sortBy;
      pagingData.Descending = state.sortDesc;
      let report = state.report as TableReport;
      if (report.clearChartAndData !== undefined) {
        report.clearChartAndData();
        
      }
      state.activeReq= staticAxios.CancelToken.source();
      reportService.loadTableData(reportData, pagingData, state.activeReq)
        .then((nreport) => {

          state.report = nreport;
          state.loaded = true;
          state.busy = false;
          let newMaxPages = nreport.totalRows == 0 ? 1 : Math.ceil(nreport.totalRows / parseInt(rowsPerPage.value, 10));//nreport.getMaxPages();
          if (maxPages.value != newMaxPages) {
            maxPages.value = newMaxPages
            currentPage.value = report.currentPage <= 0 ? 1 : report.currentPage
          }
          
          //state.maxPages = Math.ceil(nreport.totalRows / parseInt(state.mRowsPerPage, 10));//nreport.getMaxPages();
          //currentPage.value = 1;
        })
        .catch((e) => {
          if (typeof e === "string") {
            store.dispatch("reportState/" + ReportActions.SetErrorBar, e)
          } else
            if (axios.isCancel(e)) {
              store.dispatch("reportState/" + ReportActions.SetErrorBar, e.message)
            }
            else {
            
            store.dispatch("reportState/" + ReportActions.SetErrorBar, 'Error loading the report')
          }
          state.loaded = false;
          state.busy = false;
          console.log(e);
        })
    }
    const printReport = () => {
      var documenttitle = document.title;
      var printtitle = reportData.title;
      if (reportData.parameters != null) {
        for (var i = 0; i < reportData.parameters.length; i++) {
          if (reportData.parameters[i].value != null) {
            printtitle += ', ' + reportData.parameters[i].label + '=' + reportData.parameters[i].value;
          }

        }
      }

      document.title = printtitle;

      //document.title + ' ' + $('.serverclock span.placeholder').attr('data-offset') + ' ' + $('.serverclock span.placeholder').html().substr(0, 5).replace(':', '.');
      window.print();
      document.title = documenttitle;
    }
    const exportReport = () => {
      let report = state.report as TableReport;
      if (report.tableData === undefined) {
        store.dispatch("reportState/" + ReportActions.SetErrorBar, "Report not loaded");
        return;
      }
      let exportService = new ExportService(report, ExportFormat.XLS);
      state.busy = true;
      exportService.exportReport().then(() => { state.busy = false; }).catch(err => {
        store.dispatch("reportState/" + ReportActions.SetErrorBar, "Error exporting the report");
        state.busy = false;
      });
    }
    const getItemClass = (item: any): any => {
      return ['text', 'text', 'num'];
    }
    const customSort = (items: any, index: any, isDesc: any): any => {
      //loadReportData()
    }//reportService.tableCustomSort;
    const rowClass = (item: any) => {
      if (item.isTotal === undefined)
        return '';
      if (item['isTotal'] == 1)
        return 'total';
      else
        return '';
    }
    const extraSmallSize = (param: IParameterData): number => {
      if (param.type == 'date' || param.type == 'month')
        return 6;
      if (param.type == 'text')
        return 6;
      if (param.type == 'GroupedMultiSelect')
        return 12;

      return 6;
    }
    const smallSize = (param: IParameterData): number => {
      if (param.type == 'date' || param.type == 'month')
        return 4;
      if (param.type == 'text')
        return 4;
      if (param.type == 'GroupedMultiSelect')
        return 12;

      return 6;
    }
    const mediumSize = (param: IParameterData): number => {
      if (param.type == 'date' || param.type == 'month')
        return 3;
      if (param.type == 'text')
        return 3;
      if (param.type == 'GroupedMultiSelect')
        return 6;

      return 4;
    }
    const largeSize = (param: IParameterData): number => {
      if (param.type == 'date' || param.type == 'month')
        return 3;
      if (param.type == 'text')
        return 3;
      if (param.type == 'GroupedMultiSelect')
        return 6;

      return 4;
    }
    const closeDialog = () => {
      emit('closeDialog')
    }
    onMounted(() => {
      loadTableHeaders();
    });
    return {
      state,
      reportData,
      //afterChartCreate,
      loadReportData,
      exportReport,
      reportService,
      getItemClass,
      customSort,
      rowClass,
      itemFormat,
      //chartButtonClick,
      currentPage,
      rowsPerPage,
      largeSize,
      mediumSize,
      smallSize,
      extraSmallSize,
      closeDialog,
      maxPages,
      loadTableHeaders,
      options,
      cancelLoad,
      sortBy,
      sortDesc,
      printReport

    }
  }
});
