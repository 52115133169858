import { Report } from '@/services/report'
import { Tooltip } from 'highcharts'
import { TableReport } from '@/services/tableReport'
import { DrillDownReport } from '@/services/drilldownReport';

export class ChartService {
  tooltipFormatter(report: Report | TableReport): Function | null {
    switch (report.reportData.name) {
      case 'IncidentsByRouteFlight':
        return ChartService.tooltipIncidentsByRouteFlight;
    }
    return null;
  }
  static tooltipIncidentsByRouteFlight(tooltip: Tooltip): any {
    let self = this as any;
    if (self.points) {
      let first = self.points[0].point;
      let rv = '<div><div style="font-size:10pt;">$0</div><div style="font-size:8pt;margin-bottom:4px;">$1</div>$2</div>'
        .replace("$0", first.name).replace("$1", first.text);
      let tx = '';
      for (let i = 0; i < self.points.length; i++) {
        let p = self.points[i];
        tx += '<div><span style="color: $3;">$0</span>: <b>$1</b> ($2%)</div>'
          .replace("$0", p.series.name)
          .replace("$1", p.y)
          .replace("$2", p.percentage.toFixed(2))
          .replace("$3", p.series.color);
      }
      rv = rv.replace("$2", tx);
      return rv;
    }
    return null;
  }
  static getExportDataIncidentsByRouteFlight(report: Report | TableReport|DrillDownReport): any {
    if (report.serieData == null)
      return null;

    let rArr = report.serieData[0];
    let aArr = report.serieData[1];
    let gArr = report.serieData[2];

    let rv = [];
    for (let i = 0; i < rArr.length; i++) {
      rv.push({ groupName: rArr[i].chartCategory, redIncidents: rArr[i].y, amberIncidents: aArr[i].y, greenIncidents: gArr[i].y });    
    }
    return rv;
  }
  static getExportData(report: Report | TableReport|DrillDownReport): any {
    if (report.serieData != null) {
      switch (report.reportData.name) {
        case 'IncidentsByRouteFlight':
          return ChartService.getExportDataIncidentsByRouteFlight(report);
        default:
          return report.serieData[0];
      }
    }
    return null;
  }
  static setLatencyChart(chart: any, latency: number) {
    var point = chart.series[0].points[0];
    point.update(latency);
  }
  static setChartHeight(chart: any, val: number, marginTop:number) {
    chart.update({
      chart: {
        "height": val,
        "marginTop": marginTop,
      }
    });
  }
  static mapPointsToPath(from: any, to: any, binvertArc: boolean | null) {
    const invertArc = (binvertArc == null || binvertArc == false) ? false : true;
    var arcPointX = (from.x + to.x) / (invertArc ? 2.001 : 1.99),
      arcPointY = (from.y + to.y) / (invertArc ? 2.001 : 1.99);
    return 'M' + from.x + ',' + from.y + 'Q' + arcPointX + ' ' + arcPointY +
      ',' + to.x + ' ' + to.y;
  }
  static GetMapSeries(chart: Highcharts.Chart, series: any): any[] {
    const airports = (series.find((t: any) => t.name == "Airports")).data;
    const flights = (series.find((t: any) => t.name == "Flights")).data;

    const retSeries = [{
      name: 'Basemap',
      borderColor: '#A0A0A0',
      nullColor: 'rgba(200, 200, 200, 0.3)',
      showInLegend: false,
      allAreas: true
    },
    {
      type: "mappoint",
      name: "Airports",
      dataLabels: {
        format: '{point.id}'
      },
      color: "#00bb00",
      data: airports
    }];
    chart.addSeries(retSeries[0] as any);
    chart.addSeries(retSeries[1] as any);

    let lineData = [];
    for (let i = 0; i < flights.length; i++) {
      const flight = flights[i];
      const p1 = chart.get(flight.lineFrom);
      const p2 = chart.get(flight.lineTo);
      const path1 = ChartService.mapPointsToPath(p1, p2, true);
      lineData.push({
        id: flight.id,
        path: path1,
        color: "#00ff00"
      })
    }
    retSeries.push({
      type: "mapline",
      name: "Flights",
      lineWidth: 2,
      dataLabels: {
        enabled: false,
        format: '{point.id}'
      },
      events: {
        mouseOver: function (e:any) {
          console.log(e)
        }
      },
      data: lineData
    } as any);
    chart.addSeries(retSeries[2] as any);

    return retSeries;
  }
  static mapDemo(chart: any){
    for (let i = chart.series.length - 1; i >= 0; i--)
      chart.series[i].remove(true)
    chart.addSeries(
      {
        name: 'Basemap',
        borderColor: '#A0A0A0',
        nullColor: 'rgba(200, 200, 200, 0.3)',
        showInLegend: false,
        allAreas: true
      } as any)
    chart.addSeries(
      {
        // Specify points using lat/lon
        type: "mappoint",
        name: "UK cities",
        dataLabels: {
          format: '{point.id}'
        },
        color: "#00bb00",
        data: [
          {
            id: "London",
            lat: 51.507222,
            lon: -0.1275
          },
          {
            id: "Birmingham",
            lat: 52.483056,
            lon: -1.893611
          }
        ]
      })

    const p1 = chart.get('London');
    const p2 = chart.get('Birmingham');
    console.log('London', p1)
    console.log('Birmingham', p2)
    const path1 = ChartService.mapPointsToPath(p1, p2, true);
    console.log('path1', path1)

    chart.addSeries(
      {
        // Specify points using lat/lon
        type: "mapline",
        name: "UK aa",
        lineWidth: 3,
        states: {
          hover: {
            lineWidth: 1

          }
        },
        dataLabels: {
          enabled: true,
          format: '{point.id}'
        },
        events: {
          mouseOver: function (e:any) {
            console.log(e)
          }
        },
        data: [
          {
            id: "path1",
            path: path1,
            color: "#f4e2ba"
          }
        ]
      })

  }
}
