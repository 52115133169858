

















































































































import { reactive, watch, computed, onMounted, onBeforeUpdate, onActivated } from "@vue/composition-api";
import { defineComponent } from '@vue/composition-api';
import { ReportService } from '@/services/reportService'
import { ExportService, ExportFormat } from '@/services/exportService'
import { DrillDownReport } from '../services/drilldownReport';
import store from '../store';
import { ReportActions } from '@/store/actions/reportActions';
import {  DataTableHeader } from 'vuetify/types'

export default defineComponent({
  name: 'DrillDownDetails',
  props: {
    parentItem: {
      type: Object,
      required: true
    },
    parentHeaders: {
      type: Array,
      required: true
    },
  },
  components: {},
  methods: {

  },
  setup(props: any, { emit }) {
    const parent: any = reactive(props.parentItem)
    const state = reactive({
      report: {},
      // chart: {},
      loaded: false,
      busy: false,
      exportError: false
      
    });
    const reportService = new ReportService();
    const loadReportData = () => {

     
     reportService.loadDrillDown(parent)
       .then((nreport) => {
         //alert("return");
          state.report = nreport as DrillDownReport;
          state.loaded = true;
        })
        .catch((e) => {
          if (typeof e === "string") {
            store.dispatch("reportState/" + ReportActions.SetErrorBar, e)
          } else {
            store.dispatch("reportState/" + ReportActions.SetErrorBar, 'Error loading the report')
          }
          console.log(e);
        })

    }
    const exportReport = () => {
      let report = state.report as DrillDownReport;
      //alert("export details");
      if (report.tableData === undefined) {
        store.dispatch("reportState/" + ReportActions.SetErrorBar, "Report not loaded");
        return;
      }
      let exportService = new ExportService(report, ExportFormat.XLS);

      exportService.exportReport().catch(err => {
        store.dispatch("reportState/" + ReportActions.SetErrorBar, "Error exporting the report");
      });
    }
    onMounted(() => {
      loadReportData();
    });
    return {
      state,
      parent,
      loadReportData,
      exportReport,
      reportService
    }

  }

  });
