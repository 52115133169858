import { IReportData } from '@/store/stateDefinition/reportState'
import { ReportService } from './reportService';

export class TableReport {
  reportData: IReportData;
  tableData: {} | null;
  tableHeaders: {};
  tableTotals: any;
  pageSize: number;
  currentPage: number;
  currentSortBy: string;
  currentSortDesc: boolean;
  serieData: any[] | null;
  visibleRowsCount: number;
  totalRows: number;
  constructor(reportData: IReportData,tableData: {}, tableHeaders: {}, ) {
    this.reportData = reportData;
    this.tableData = tableData;
    this.tableHeaders = tableHeaders;
    this.currentPage = 0;
    this.pageSize = 99999;
    this.currentSortBy = '';
    this.currentSortDesc = true;
    this.serieData = null;
   // this.serieData = this.chartSeries?.map(t => t.data) || null;
    this.visibleRowsCount = 0;
    this.tableTotals = null;
    this.totalRows = 0;
    //if (tableData != null && Array.isArray(tableData)) {
    //  if (tableData[0].isTotal !== undefined) {
    //    this.tableTotals = tableData.filter(t => t.isTotal);
    //    this.tableData = tableData.filter(t => !t.isTotal);
    //  }
    //}
  }
  updateTableContent() {

  }
  static setDateParameters(reportData: IReportData) {
    if (reportData.parameters != null) {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, '0');
      var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = today.getFullYear();

      for (let i = 0; i < reportData.parameters.length; i++) {
        let p = reportData.parameters[i];
        switch (p.type) {
          case 'date':
            if (p.value != '') {
              const parsed = parseInt(p.value, 10);
              if (!isNaN(parsed)) {

                today.setDate(today.getDate() + parsed);
                dd = String(today.getDate()).padStart(2, '0');
                mm = String(today.getMonth() + 1).padStart(2, '0');
                yyyy = today.getFullYear();
              }
            }
            else {
              p.value = yyyy + '-' + mm + '-' + dd;

            }
            break;
          case 'month':
            p.value = yyyy + '-' + mm;
            break;
        }
      }
    }
  }
  isColumnNumeric(columnName: string) {
    let newseries = this.serieData;
    if (newseries != null && newseries.length > 0) {
      for (var i = 0; i < newseries.length; i++) {
        let serie = newseries[i];
        for (var n = 0; n < serie.length; n++) {
          let ta = serie[n][columnName];
          if (ta !== null && typeof ta !== 'number')
            return false;
        }
      }
    }
    return true;
  }
  sortByColumn(columnName: string) {
    let newseries = this.serieData;
    let reportSerice = new ReportService();
    if (newseries != null && newseries.length > 0) {
      if (columnName === this.currentSortBy) {
        this.currentSortDesc = !this.currentSortDesc;
      }
      else {
        this.currentSortDesc = this.isColumnNumeric(columnName);
        this.currentSortBy = columnName;
      }
      for (var i = 0; i < newseries.length; i++) {
        let serieDataI = newseries[i];
        if (serieDataI !== null)
          reportSerice.tableCustomSort(serieDataI, columnName, this.currentSortDesc);
      }
    }
   // this.updateChartContent();
  }
  getMaxSerieLenght(): number {
    let newseries = this.serieData;
    let max = 0;
    if (newseries != null) {
      for (var i = 0; i < newseries.length; i++) {
        let serie = newseries[i];
        if (max < serie.length)
          max = serie.length;
      }
    }
    return max;
  }
  getMaxPages(): number {
    if (this.pageSize <= 0)
      return 0;
    if (this.tableData == null || !Array.isArray(this.tableData))
      return 0;
    
    let max = this.tableData.length;//this.getMaxSerieLenght();
    let pages = max / this.pageSize;
    let intPages = ~~pages;
    if (intPages < pages)
      intPages++;
    return intPages;
  }
  setPageSize(newsize: number) {
    this.pageSize = newsize;
    let max = this.getMaxPages();
    if (this.currentPage > max)
      this.currentPage = max;
    //this.updateChartContent();
  }
  //updateChartContent() { }
  setPage(newpage: number) {
    this.currentPage = newpage;
    if (this.currentPage < 0)
      this.currentPage = 0;
    let max = this.getMaxPages();
    if (this.currentPage > max)
      this.currentPage = max;
    this.updateTableContent();
  }
  clearChartAndData() {
    //for (var i = this.chart.series.length - 1; i >= 0; i--) {
    //  this.chart.series[i].remove(true);
    //}
    //this.chart.redraw();
    this.tableData = [];
    this.serieData = null;
  }
}
