import { MutationTree, ActionTree, GetterTree, Module } from 'vuex'
import { ReportState, ReportHomeResultState, ReportAirlineState, AirlineConfigState, IReportData, ReportTimeZone, eCookiePolicy } from '@/store/stateDefinition/reportState';
import { RootState } from '@/store/stateDefinition/rootState'
import { ReportActions } from '@/store/actions/reportActions'
import { ReportService } from '@/services/reportService'
import { mapGetters } from 'vuex'
import { UserService } from '@/services/userService'
import Vue from 'vue'
import { CookiesOption } from 'vue-cookies-ts';

const baseURL = (window as any).VueConfig.VUE_APP_ROOT_API + "report/";
const cookieKeyTimeZone = 'timeZoneId';
const cookiePolicy = 'cookiePolicy';

const state : ReportState =  {
  reportHomeResult: null,
  reportAirlines: [],
  selectedAirlines: [],
  allAirlineConfigs: [],
  airlineConfigsNames: [],
  selectedAirlineConfigsNames: [],
  allReports: [],
  timeZones: [],
  selectedTimeZone: null,
  userSelectionChange: 0,
  userConfirmedSelectionChange: 0,
  ready: false,
  readyDate: 0,
  errorBarShow: false,
  errorBarDate: 0,
  selectedReportId: 0,
  selectedReportUrl:'',
  errorBarText: '',
  dialogReport: null,
  dialogReportKey: 0,
  cookiePolicy: eCookiePolicy.notSet
}

function isStateReady(): boolean {
  return state.selectedAirlines.length > 0 && state.selectedAirlineConfigsNames.length > 0
    && state.airlineConfigsNames.length > 0 && state.selectedAirlineConfigsNames.length > 0
    && state.timeZones.length > 0 && state.selectedTimeZone != null
    && state.allReports.length > 0;
}

enum ReportMutations {
  SetAirlines = 'SET_AIRLINES',
  SetTimeZones = 'SET_TIMEZONES',
  SetSelectedTimeZone = 'SET_SELECTED_TIMEZONE',
  SetSelectedAirlines = 'SET_SELECTED_AIRLINES',
  SetAllConfigurations = 'SET_ALL_CONFIGS',
  SetConfigurations = 'SET_CONFIGURATIONS',
  SetSelectedConfiguration = 'SET_SELECTED_CONFIGURATION',
  SetReports = 'SET_REPORTS',
  SetUserSelectionChanged = 'SET_USER_SELECTION_CHANGED',
  SetConfirmedUserSelectionChange = 'SET_CONFIRMED_USER_SELECTION_CHANGED',
  SetReady = 'SET_READY',
  SetErrorBar = 'SET_ERRORBAR',
  SetSelectedReportId = 'SET_SELECTEDREPORTID',
  SetSelectedReportUrl = 'SET_SELECTEDREPORTURL',
  SetDialogReport = 'SET_DIALOG_REPORT',
  SetCookiePolicy = 'SET_COOKIE_POLICY'
}


const getters: GetterTree<ReportState, RootState> = {
  reportAirlinesGetter: (state) => {
    return state.reportAirlines;
  },
  selectedAirlinesGetter: (state) => {
    return state.selectedAirlines;
  },
  selectedReportId: (state) => {
    return state.selectedReportId;
  },
  selectedReportUrl: (state) => {
    return state.selectedReportUrl;
  },
  airlineConfigsGetter: (state) => {
    return state.airlineConfigsNames;
  },
  selectedAirlineConfigsGetter: (state) => {
    return state.selectedAirlineConfigsNames;
  },
  
  fullReportsGetter: (state) => {
    return state.allReports.filter(t => t.isFullReport && (t.hideFromList == null || t.hideFromList == false));
  },
  dashboardReportsGetter: (state) => {
    return state.allReports.filter(t => !t.isFullReport && t.id != -1 && (t.isMap==null||t.isMap==false));
  },
  dashboardMapReportsGetter: (state) => {
    return state.allReports.filter(t => !t.isFullReport && t.isMap == true);
  },
  summaryReportsGetter: (state) => {
    return state.allReports.filter(t => t.id == -1);
  },
  userSelectionChange: (state) => {
    return state.userConfirmedSelectionChange;
  },
  readygetter: (state) => {
    return state.ready;
  },
  readyDate: (state) => {
    return state.readyDate;
  },
  selectedTimeZone: (state) => {
    return state.selectedTimeZone;
  },
  timeZones: (state) => {
    return state.timeZones;
  },
  errorBarShow: (state) => {
    return state.errorBarShow;
  },
  errorBarDate: (state) => {
    return state.errorBarDate;
  },
  errorBarText: (state) => {
    return state.errorBarText;
  },
  dialogReportGetter: (state) => {
    return state.dialogReport;
  },
  dialogReportKeyGetter: (state) => {
    return state.dialogReportKey;
  },
  cookiePolicy: (state) => {
    return state.cookiePolicy;
  },
};

const mutations: MutationTree<ReportState> = {
  [ReportMutations.SetCookiePolicy](state, payload: eCookiePolicy) {
    state.cookiePolicy = payload;
  },
  [ReportMutations.SetAirlines](state, payload: ReportAirlineState[]) {
    state.reportAirlines = payload;
  },
  [ReportMutations.SetTimeZones](state, payload: ReportTimeZone[]) {
    state.timeZones = payload;
  },
  [ReportMutations.SetSelectedTimeZone](state, payload: ReportTimeZone) {
    state.selectedTimeZone = payload;
  },
  [ReportMutations.SetSelectedAirlines](state, payload: ReportAirlineState[]) {
    state.selectedAirlines = payload;
  },
  [ReportMutations.SetAllConfigurations](state, payload: AirlineConfigState[]) {
    state.allAirlineConfigs = payload;
  },
  [ReportMutations.SetConfigurations](state, payload: string[]) {
    state.airlineConfigsNames = payload;
  },
  [ReportMutations.SetSelectedConfiguration](state, payload: string[]) {
    state.selectedAirlineConfigsNames = payload;
  },
  [ReportMutations.SetReports](state, payload: IReportData[]) {
    state.allReports = payload;
  },
  [ReportMutations.SetUserSelectionChanged](state) {
    state.userSelectionChange = Date.now();
  },
  [ReportMutations.SetConfirmedUserSelectionChange](state) {
    state.userConfirmedSelectionChange = state.userSelectionChange;
  },
  [ReportMutations.SetReady](state, payload: boolean) {
    state.readyDate = Date.now();
    state.ready = payload;
  },
  [ReportMutations.SetSelectedReportId](state, payload: number) {
    state.selectedReportId = payload;
  },
  [ReportMutations.SetSelectedReportUrl](state, payload: string) {
    state.selectedReportUrl = payload;
  },
  [ReportMutations.SetErrorBar](state, payload: string|null) {
    if (payload == null) {
      state.errorBarShow = false;
      state.errorBarText = '';
    }
    else {
      state.errorBarShow = true;
      state.errorBarDate = Date.now();
      state.errorBarText = payload;

    }
  },
  [ReportMutations.SetDialogReport](state, payload: IReportData) {
    state.dialogReport = payload;
    state.dialogReportKey = Date.now();
  },
}

const actions: ActionTree<ReportState, RootState> = {
  [ReportActions.LoadCookiePolicy]({ commit, state }): Promise<eCookiePolicy> {
    return new Promise((resolve, reject) => {
      let policy= eCookiePolicy.notSet;
      try {
        if (Vue.cookies.isKey(cookiePolicy)) {
          const spolicy = Vue.cookies.get(cookiePolicy);
          if (spolicy != null) {
            const tpolicy: eCookiePolicy = parseInt(spolicy as any);
            policy = tpolicy;
          }
        }
      }
      catch {
      }
      commit(ReportMutations.SetCookiePolicy, policy);
      resolve(policy);
    })
  },
  [ReportActions.SaveCookiePolicy]({ commit, state }, payload:eCookiePolicy): Promise<void> {
    return new Promise((resolve, reject) => {
      commit(ReportMutations.SetCookiePolicy, payload);
      const options: CookiesOption = (window as any).VueConfig.cookieOptions;
      Vue.cookies.set(cookiePolicy, (payload as number).toString(), options)
    })
  },
  [ReportActions.LoadHomeReport]({ commit, state }): Promise<ReportHomeResultState> {
    return new Promise((resolve, reject) => {
      state.reportHomeResult = null;
      reject();
        //resolve(state.reportHomeResult);
    })
  },
  [ReportActions.LoadAirlines]({ commit, state }): Promise<ReportAirlineState[]> {
    return new Promise((resolve, reject) => {
      (new ReportService()).getReportAirlines().then(
        t => {
          if (t != null) {
            commit(ReportMutations.SetAirlines, t);
            commit(ReportMutations.SetSelectedAirlines, [t[0]]);
            if (!state.ready && isStateReady()) {
              commit(ReportMutations.SetReady, true);            
              commit(ReportMutations.SetUserSelectionChanged);
              commit(ReportMutations.SetConfirmedUserSelectionChange);
            }
            resolve(t);
          }
          else
            reject();
        },
        err => {
          console.log('error', err);
          reject(err);
        });
    })
  },
  [ReportActions.LoadTimeZones]({ commit, state }): Promise<ReportTimeZone[]> {
    return new Promise((resolve, reject) => {
      (new ReportService()).getTimeZones().then(
        t => {
          if (t != null) {
            commit(ReportMutations.SetTimeZones, t);

            let timezoneid: any;
            if (Vue.cookies.isKey(cookieKeyTimeZone))
              timezoneid = Vue.cookies.get(cookieKeyTimeZone);
            if (timezoneid === undefined || timezoneid == '' || timezoneid == null)
              timezoneid = 35;

            let selectedTimeZone = t.find(t => t.id == timezoneid);
            if (selectedTimeZone === undefined)
              selectedTimeZone = t[0];

            Vue.cookies.set(cookieKeyTimeZone, selectedTimeZone.id.toString())
            commit(ReportMutations.SetSelectedTimeZone, selectedTimeZone);

            if (!state.ready && isStateReady()) {
              commit(ReportMutations.SetReady, true);
              commit(ReportMutations.SetUserSelectionChanged);
              commit(ReportMutations.SetConfirmedUserSelectionChange);
            }
            resolve(t);
          }
          else
            reject();
        },
        err => {
          console.log('error', err);
          reject(err);
        });
    })
  },
  [ReportActions.LoadConfigurations]({ commit, state }): Promise<AirlineConfigState[]> {
    const rs = new ReportService();
    return new Promise((resolve, reject) => {
      rs.getAirlineConfigurations().then(
        t => {
          if (t != null) {
            commit(ReportMutations.SetAllConfigurations, t);
            let names = rs.getAirlineConfigsNames(t, state.selectedAirlines, true);
            commit(ReportMutations.SetConfigurations, names);
            commit(ReportMutations.SetSelectedConfiguration, [names[0]]);
            if (!state.ready && isStateReady()) {
              commit(ReportMutations.SetReady, true);
              commit(ReportMutations.SetUserSelectionChanged);
              commit(ReportMutations.SetConfirmedUserSelectionChange);
            }
            resolve(t);
          }
          else
            reject();
        },
        err => {
          console.log('error', err);
          reject(err);
        });
    })
  },
  [ReportActions.LoadReportsFile]({ commit, state }): Promise<IReportData[]> {
    return new Promise((resolve, reject) => {
      (new ReportService()).fetchReports().then(
        t => {
          if (t != null) {
            commit(ReportMutations.SetReports, t);
            if (!state.ready && isStateReady()) {
              commit(ReportMutations.SetReady, true);
              commit(ReportMutations.SetUserSelectionChanged);
              commit(ReportMutations.SetConfirmedUserSelectionChange);
            }
            resolve(t);
          }
          else
            reject();
        },
        err => {
          console.log('error', err);
          reject(err);
        });
    })
  },
  [ReportActions.SetSelectedAirlines]({ commit, state }, payload: ReportAirlineState[]): Promise<void> {
    return new Promise((resolve) => {
      let airlines = ReportService.airlineSelectionFilter(state.selectedAirlines, state.reportAirlines, payload);
      commit(ReportMutations.SetSelectedAirlines, airlines);

      let rs = new ReportService();
      let names = rs.getAirlineConfigsNames(state.allAirlineConfigs, state.selectedAirlines, true);
      commit(ReportMutations.SetConfigurations, names);

      let selected = rs.getSelectedAirlineConfigsNames(names, state.selectedAirlineConfigsNames);
      commit(ReportMutations.SetSelectedConfiguration, selected);

      commit(ReportMutations.SetUserSelectionChanged);
      resolve();
    })
  },
  [ReportActions.SetSelectedConfigs]({ commit, state }, payload: string[]): Promise<void> {
    return new Promise((resolve) => {
      let configurations = ReportService.configurationSelectionFilter(state.selectedAirlineConfigsNames, state.airlineConfigsNames, payload);
      commit(ReportMutations.SetSelectedConfiguration, configurations);
      commit(ReportMutations.SetUserSelectionChanged);
      resolve();
    })
  },
  [ReportActions.SetConfirmedSelectionChanges]({ commit, state }): Promise<void> {
    return new Promise((resolve) => {
      if(state.userSelectionChange!=state.userConfirmedSelectionChange)
        commit(ReportMutations.SetConfirmedUserSelectionChange);
      resolve();
    })
  },
  [ReportActions.SetSelectedTimeZone]({ commit, state }, payload: ReportTimeZone): Promise<void> {
    return new Promise((resolve) => {
      Vue.cookies.set(cookieKeyTimeZone, payload.id.toString())

      commit(ReportMutations.SetSelectedTimeZone, payload);
      commit(ReportMutations.SetUserSelectionChanged);
      resolve();
    })
  },
  [ReportActions.SetErrorBar]({ commit }, payload: string|null): Promise<void> {
    return new Promise((resolve) => {
      commit(ReportMutations.SetErrorBar, payload);
      resolve();
    })
  },
  [ReportActions.SetSelectedReportId]({ commit }, payload: number): Promise<void> {
    return new Promise((resolve) => {
      commit(ReportMutations.SetSelectedReportId, payload);
      if (payload == 0) {
        commit(ReportMutations.SetSelectedReportUrl, '');
      }
      else {
        const selReport = state.allReports.filter(t => t.id == payload);
        if (selReport.length > 0) {
          commit(ReportMutations.SetSelectedReportUrl, selReport[0].reportUrl);
        }
      }
      resolve();
    })
  },
  [ReportActions.SetSelectedReportUrl]({ commit }, payload: string): Promise<void> {
    return new Promise((resolve, reject) => {
      if (payload == '') {
        commit(ReportMutations.SetSelectedReportId, 0);
        commit(ReportMutations.SetSelectedReportUrl, '');
        resolve();
      }
      else {
        const selReport = state.allReports.filter(t => t.isFullReport && t.reportUrl == payload);
        if (selReport.length > 0 && selReport[0].id !== undefined) {
          commit(ReportMutations.SetSelectedReportId, selReport[0].id);
          commit(ReportMutations.SetSelectedReportUrl, selReport[0].reportUrl);
          resolve();
        }
        else
          reject();
      }
    })
  },
  [ReportActions.ShowDialogReport]({ commit }, payload: IReportData): Promise<void> {
    return new Promise((resolve) => {
      commit(ReportMutations.SetDialogReport, payload);
      resolve();
    })
  }
}

const ReportModule = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default ReportModule as Module<ReportState, RootState>;
