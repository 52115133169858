import { render, staticRenderFns } from "./AutocompleteComponent.vue?vue&type=template&id=44b97a2e&scoped=true&"
import script from "./AutocompleteComponent.vue?vue&type=script&lang=ts&"
export * from "./AutocompleteComponent.vue?vue&type=script&lang=ts&"
import style0 from "vue-multiselect/dist/vue-multiselect.min.css?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44b97a2e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
installComponents(component, {VAutocomplete})
