












import { defineComponent, reactive, ref, watch, computed, onMounted } from "@vue/composition-api";
import { ReportActions } from '@/store/actions/reportActions'
import { eCookiePolicy } from '@/store/stateDefinition/reportState'
import Vue from 'vue';

export default defineComponent({
  name: 'App',
  components: {      
  },
  setup(props, context) {
    const rstore = context.root.$store;

    //onMounted(() => {
    //  window.addEventListener('vuexoidc:userLoaded',
    //    () => {
    //      loadPageData();
    //    });
    //})

    const isAuthenticated = computed(() => rstore.getters["oidcStore/oidcIsAuthenticated"]);      
    watch(isAuthenticated, (newValue, oldValue) => {
      const ready = rstore.getters["reportState/readygetter"];
      if (!ready.value) {
        loadPageData();
      }
    });


    const errorBarDate = computed(() => rstore.getters["reportState/errorBarDate"]);
    const errorBarShow = computed(
      {
        get: () => rstore.getters["reportState/errorBarShow"],
        set: (value) => {
          if (!value) rstore.dispatch("reportState/" + ReportActions.SetErrorBar, null); }
      });
    const errorBarText = computed(() => {
      let now = new Date();
      let hours = now.getHours();
      let minutes = now.getMinutes();
      let strMinutes = minutes < 10 ? '0' + minutes : minutes;
      return hours + ':' + strMinutes + '   ' + rstore.getters["reportState/errorBarText"]
    });

    const loadPageData = () => {
      const isReady = computed(() => rstore.getters["oidcStore/readygetter"]);
      if (isReady.value !== true) {
        rstore.dispatch("reportState/" + ReportActions.LoadCookiePolicy).then((t) => {
          if (t == eCookiePolicy.accepted) {
            (Vue as any).appInsights.config.disableTelemetry = false;
          }
        });
        const isAuthenticated = computed(() => rstore.getters["oidcStore/oidcIsAuthenticated"]);
        if (isAuthenticated.value) {
          rstore.dispatch("reportState/" + ReportActions.LoadTimeZones)
            .then(() => rstore.dispatch("reportState/" + ReportActions.LoadAirlines))
            .then(() => rstore.dispatch("reportState/" + ReportActions.LoadConfigurations))
            .then(() => rstore.dispatch("reportState/" + ReportActions.LoadReportsFile))
            .catch((e) => {
              console.log('Network error',e);
              rstore.dispatch("reportState/" + ReportActions.SetErrorBar, 'Network error')
              setTimeout(loadPageData, 1500);
            })
        }
      }
    }

    return {
      errorBarShow,
      errorBarText,
      errorBarDate        
    }
  }
})
