import { computed } from "@vue/composition-api";
import store from '@/store/index';

export enum OidcActions {
  addOidcEventListener = "oidcStore/addOidcEventListener",
  authenticateOidc = "oidcStore/authenticateOidc",
  authenticateOidcPopup = "oidcStore/authenticateOidcPopup",
  authenticateOidcSilent = "oidcStore/authenticateOidcSilent",
  clearStaleState = "oidcStore/clearStaleState",
  getOidcUser = "oidcStore/getOidcUser",
  oidcCheckAccess = "oidcStore/oidcCheckAccess",
  oidcSignInCallback = "oidcStore/oidcSignInCallback",
  oidcSignInPopupCallback = "oidcStore/oidcSignInPopupCallback",
  oidcWasAuthenticated = "oidcStore/oidcWasAuthenticated",
  removeOidcEventListener = "oidcStore/removeOidcEventListener",
  removeOidcUser = "oidcStore/removeOidcUser",
  removeUser = "oidcStore/removeUser",
  signOutOidc = "oidcStore/signOutOidc",
  signOutOidcCallback = "oidcStore/signOutOidcCallback",
  signOutOidcSilent = "oidcStore/signOutOidcSilent",
  signOutPopupOidc = "oidcStore/signOutPopupOidc",
  signOutPopupOidcCallback = "oidcStore/signOutPopupOidcCallback",
  storeOidcUser = "oidcStore/storeOidcUser",
}


// export const OidcActions = {
//     addOidcEventListener: "oidcStore/addOidcEventListener",
//     authenticateOidc : "oidcStore/authenticateOidc",
//     authenticateOidcPopup : "oidcStore/authenticateOidcPopup",
//     authenticateOidcSilent : "oidcStore/authenticateOidcSilent",
//     clearStaleState : "oidcStore/clearStaleState",
//     getOidcUser : "oidcStore/getOidcUser",
//     oidcCheckAccess : "oidcStore/oidcCheckAccess",
//     oidcSignInCallback : "oidcStore/oidcSignInCallback",
//     oidcSignInPopupCallback : "oidcStore/oidcSignInPopupCallback",
//     oidcWasAuthenticated : "oidcStore/oidcWasAuthenticated",
//     removeOidcEventListener : "oidcStore/removeOidcEventListener",
//     removeOidcUser : "oidcStore/removeOidcUser",
//     removeUser : "oidcStore/removeUser",
//     signOutOidc : "oidcStore/signOutOidc",
//     signOutOidcCallback : "oidcStore/signOutOidcCallback",
//     signOutOidcSilent : "oidcStore/signOutOidcSilent",
//     signOutPopupOidc : "oidcStore/signOutPopupOidc",
//     signOutPopupOidcCallback : "oidcStore/signOutPopupOidcCallback",
//     storeOidcUser : "oidcStore/storeOidcUser",
// }

export const GenerateOidcGetters = () => {
  return {
    oidcAccessToken: computed(() => store.getters["oidcStore/oidcAccessToken"]),
    oidcAccessTokenExp: computed(() => store.getters["oidcStore/oidcAccessTokenExp"]),
    oidcAuthenticationIsChecked: computed(() => store.getters["oidcStore/oidcAuthenticationIsChecked"]),
    oidcError: computed(() => store.getters["oidcStore/oidcError"]),
    oidcIdToken: computed(() => store.getters["oidcStore/oidcIdToken"]),
    oidcIdTokenExp: computed(() => store.getters["oidcStore/oidcIdTokenExp"]),
    oidcIsAuthenticated: computed(() => store.getters["oidcStore/oidcIsAuthenticated"]),
    oidcIsRoutePublic: computed(() => store.getters["oidcStore/oidcIsRoutePublic"]),
    oidcRefreshToken: computed(() => store.getters["oidcStore/oidcRefreshToken"]),
    oidcRefreshTokenExp: computed(() => store.getters["oidcStore/oidcRefreshTokenExp"]),
    oidcScopes: computed(() => store.getters["oidcStore/oidcScopes"]),
    oidcUser: computed(() => store.getters["oidcStore/oidcUser"]),
  }
};
