






import router from '@/router'
import UserComponent from '@/components/UserComponent.vue'

export default {
  components: { UserComponent },
  setup():any {
    const userGuid: any = router.currentRoute.params['userGuid'];
    const surname: any = decodeURIComponent(router.currentRoute.params['surname']);
    const firstname: any = decodeURIComponent(router.currentRoute.params['firstname']);
    const email: any = decodeURIComponent(router.currentRoute.params['email']);
    const back: any = decodeURIComponent(router.currentRoute.params['back']);
    const confirmEdit: any = router.currentRoute.params['confirmEdit'] === undefined ? null : decodeURIComponent(router.currentRoute.params['confirmEdit']);
    return {
      userGuid,
      surname,
      firstname,
      email,
      back,
      confirmEdit
    }
  }
}
