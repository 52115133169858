











import { defineComponent } from '@vue/composition-api';
import { reactive } from "@vue/composition-api";
import { IParameterData } from "@/store/stateDefinition/reportState"
import { ReportService } from '@/services/reportService'

export default defineComponent({
  props: {
    param: {
      type: Object,
      required: true
    }
  },
  setup(props: any) {
    const parameterData: IParameterData = props.param;
    const reportService = new ReportService();

    const state = reactive({
      selection: ''
    });

    state.selection = parameterData.value;

    const save = (item: any) => {
      parameterData.value = state.selection;
    }
    return {
      state,
      save,
      parameterData
    }
  },
});
