






















import { reactive, onBeforeUnmount, watch } from "@vue/composition-api";
import { defineComponent } from '@vue/composition-api';
import { IReportData } from "@/store/stateDefinition/reportState"
import { ReportService } from '@/services/reportService'
import { SummaryFileService } from '@/services/summaryFileService'
import store from '../store';
import { ReportActions } from '@/store/actions/reportActions'
import { Report } from '../services/report';

export default defineComponent({
  name: 'MonthlySummariesReport',
  props: {
    report: {
      type: Object,
      required: true
    }
  },
  setup(props: any) {
    const reportData: IReportData = props.report;
    const state = reactive({
      report: {},
      busy:false,
    });
    const reportService = new ReportService();

    const downloadFile = (filename: string) => {
      let report = state.report as Report;
      if (report.tableData === undefined) {
        store.dispatch("reportState/" + ReportActions.SetErrorBar, "Report not loaded");
        return;
      }
      let fileService = new SummaryFileService(report, filename);
      state.busy = true;
      fileService.downloadFile()
        .then(() => {
          state.busy = false;
        })
        .catch(err => {
          store.dispatch("reportState/" + ReportActions.SetErrorBar, "Error downloading the seelcted file");
          state.busy = false;
        });
    }

    const loadData = () => {
      reportService.loadReportData(reportData, null)
        .then((nreport) => {
          state.report = nreport;
        })
        .catch((e) => {
          if (typeof e === "string") {
            store.dispatch("reportState/" + ReportActions.SetErrorBar, e)
          } else {
            store.dispatch("reportState/" + ReportActions.SetErrorBar, 'Error loading the report')
          }
        })
    }
    loadData();

    return {
      state,
      reportService,
      downloadFile,
    }
  }
});
