import { IReportData } from '@/store/stateDefinition/reportState'
import { ReportService } from './reportService';

export class DrillDownReport {
  reportData: IReportData;
  serieData: any[] | null;
  tableData: {} | null;
  tableHeaders: {};
  //documents: Documents | null;
  //segments: Segments | null;
  
  constructor(reportData: IReportData, tableData: {}, tableHeaders: {}/*, documents: Documents | null, segments: Segments | null*/) {
    this.reportData = reportData;
    this.serieData = null;
    this.tableData = tableData;
    this.tableHeaders = tableHeaders;
  
    
  }
}
export class Documents {
  tableData: {} | null;
  tableHeaders: {};
  //documentMessages: Messages | null;
  constructor(tableData: {}, tableHeaders: {}/*, messages: Messages | null*/) {
    this.tableData = tableData;
    this.tableHeaders = tableHeaders;
    //this.documentMessages = messages;
  }
}
export class Segments {
  tableData: {} | null;
  tableHeaders: {};
//  level1Messages: Messages | null;
//  level2Messages: Messages | null;
  constructor(tableData: {}, tableHeaders: {}/*, level1Messages: Messages | null, level2Messages: Messages | null*/) {
    this.tableData = tableData;
    this.tableHeaders = tableHeaders;
   // this.level1Messages = level1Messages;
   // this.level2Messages = level2Messages;
  }
}
export class Messages {
  messageCode: string;
  messageType: string;
  messageID: number | null;
  constructor(messageCode: string, messageType: string, messageID: number | null)
  {
    this.messageCode = messageCode;
    this.messageType = messageType;
    this.messageID = messageID;
  }
} 
