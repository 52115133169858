<template>
  <div>
  </div>
</template>

<script>
  import { onMounted } from "@vue/composition-api";
  import store from '@/store/index';
  import { OidcActions } from '@/store/utils/oidc-store-helpers'

  export default {
    name: 'oidc-popup-callback',
    setup() {
      onMounted(() => { store.dispatch(OidcActions.oidcSignInPopupCallback).catch((err) => console.log(err)) });
    }
  }
</script>
