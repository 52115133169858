





















import { reactive, onBeforeUnmount, watch } from "@vue/composition-api";
import { defineComponent } from '@vue/composition-api';
import { IReportData } from "@/store/stateDefinition/reportState"
import { ReportService } from '@/services/reportService'
import { ChartService } from '@/services/chartService'
import { Chart } from 'highcharts';
import store from '../store';
import { ReportActions } from '@/store/actions/reportActions'
import { Report } from '../services/report';
import { useWindowSize } from 'vue-window-size';

export default defineComponent({
  name: 'ReportComponent',
  props: {
    report: {
      type: Object,
      required: true
    }
  },
  setup(props: any, context) {
    const reportData: IReportData = props.report;
    Report.setDateParameters(reportData);

    const state = reactive({
      report: {},
      passengers: 0,
      latency: 0.001,
      loaded: false,
      chartCreated: 0,
      latencyChart: {},
      colorChart: {},
      display: "display:none",
      intervalHandle: 0
    });
    const reportService = new ReportService();

    const afterChartCreateLatency = (chart: Highcharts.Chart) => {
      state.latencyChart = chart;
      ChartService.setLatencyChart(state.latencyChart, state.latency);
    }
    const afterChartCreate = (chart: Highcharts.Chart) => {
      state.colorChart = chart;
      resizeChart();
      state.chartCreated += 1;
      startLoadData();
    }
    const startLoadData = () => {
      if (state.intervalHandle == 0) {
        loadData();
        let aAutoRefreshMSec = 0;
        try {
          aAutoRefreshMSec = parseInt((window as any).VueConfig.summaryAutoRefreshMSec);
        }
        catch (e) {
          aAutoRefreshMSec = 0;
        }
        if (aAutoRefreshMSec < 30000)
          aAutoRefreshMSec = 30000;

        state.intervalHandle = setInterval(loadData, aAutoRefreshMSec);
      }
    }

    const { width, height } = useWindowSize();
    watch(width, (newValue, oldValue) => {
      resizeChart();
    })
    const resizeChart = () => {
      if ((state.colorChart as any).series === 'undefined')
        return;

      if (width.value <= 320) {
        ChartService.setChartHeight(state.colorChart, 120, -60);
      }
      else if (width.value < 490) {
        ChartService.setChartHeight(state.colorChart, 100, -50);
      }
      else {
        ChartService.setChartHeight(state.colorChart, 80, -32);
      }
    }

    const loadData = () => {
      reportService.loadReportData(reportData, state.colorChart as Chart)
        .then((nreport) => {
          state.report = nreport;
          if (nreport.reportData != null && (nreport.tableData as Array<object>).length == 2) {
            let values = nreport.tableData as Array<object>;
            state.passengers = values[0] as any;
            state.latency = values[1] as any;
            if (state.loaded)
              ChartService.setLatencyChart(state.latencyChart, state.latency);
          }
          state.loaded = true;
          state.display = "display:block";
        })
        .catch((e) => {
          console.log("loadData error", e)
          if (typeof e === "string") {
            store.dispatch("reportState/" + ReportActions.SetErrorBar, e)
          } else {
            store.dispatch("reportState/" + ReportActions.SetErrorBar, 'Error loading the report')
          }
        })
    }

    onBeforeUnmount(() => {
      clearInterval(state.intervalHandle);
      state.intervalHandle = 0;
      state.loaded = false;
    });

    return {
      state,
      afterChartCreate,
      afterChartCreateLatency,
      reportService,
    }
  }
});
