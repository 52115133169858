





import { onMounted } from "@vue/composition-api";
import { OidcActions } from '@/store/utils/oidc-store-helpers'
import router from '@/router/index';
import store from '@/store/index';

export default {
  name: 'oidc-callback',
  setup() {
    onMounted(() => {
      //let ReloadAfterMSecs = (window as any).VueConfig?.ReloadAfterMSecs;
      //if (ReloadAfterMSecs !== undefined && ReloadAfterMSecs != null && ReloadAfterMSecs.length > 0) {
      //  setTimeout(function () {
      //    window.location.reload();
      //  }, parseInt(ReloadAfterMSecs));
      //}

      store
        .dispatch(OidcActions.oidcSignInCallback)
        .then((redirectPath) => {
          router.push(redirectPath)
        })
        .catch((err) => {
          console.error('Error in oidcSigninCallback', err)
          router.push('/oidc-callback-error') // Handle errors any way you want
        })
    });
  }
}
