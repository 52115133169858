import axios from "@/config/axiosSetup"
import { AxiosRequestConfig } from "axios"
import { Report } from '@/services/report'
import { TableReport } from '@/services/tableReport'
import { UserService } from '@/services/userService'
import store from '@/store'
import { ChartService } from './chartService'
import { DrillDownReport } from './drilldownReport'

const baseURL = (window as any).VueConfig.VUE_APP_ROOT_API + "export/";

export enum ExportFormat {
  XLS = 0,
  XLSX = 1,
  PDF = 2,
  CSV = 3,
  DOCX = 4,
  DOC = 5,
  XML = 6
}

class ExportData {
  data: {} | null;
  format: ExportFormat;
  params: any;
  airlinesName: string;
  configsName: string;
  timezoneId: number;

  constructor(report: Report|TableReport|DrillDownReport, format: ExportFormat) {
    if (report.reportData.exportChartData == true)
      this.data = ChartService.getExportData(report);
    else
      this.data = report.tableData;
    this.format = format;

    let reportParameters: any = {};
    let aparams = report.reportData.parameters;
    if (aparams != null) {
      for (let i = 0; i < aparams.length; i++) {
        let p = aparams[i];
        reportParameters[p.paramname] = p.value;
      }
    }

    this.params = [ reportParameters ];
    this.airlinesName = store.state.reportState?.selectedAirlines.map(t => t.iata).join(',') || '';
    this.configsName = store.state.reportState?.selectedAirlineConfigsNames.join(',') || '';
    this.timezoneId = store.state.reportState?.selectedTimeZone?.id||0;
  }
}

export class ExportService {
  userService: UserService;
  exportData: ExportData;
  report: Report | TableReport | DrillDownReport ;
  format: ExportFormat
  constructor(report: Report | TableReport | DrillDownReport , format: ExportFormat) {
    this.exportData = new ExportData(report, format);
    this.userService = new UserService();
    this.report = report;
    this.format = format;
  }
  async exportDrillDown(): Promise<void> {
    const self = this;
    return new Promise(function (resolve, reject) {
      //self.userService.getToken()
      //  .then((access_token) => {
      try {

        const FileName = self.report.reportData.name + '.xlsx';
        const url = baseURL + self.report.reportData.name;//"DrillDownDetails";
            //self.report.reportData.name;
          let format = ExportFormat.XLSX;
        let data = self.exportData;
        console.log(JSON.stringify(data));
          const config: AxiosRequestConfig = {
            responseType: "blob",
            //headers: {
            //  'Content-Type': 'application/json',
            //  'Authorization': 'Bearer ' + access_token
            //}
          };
          return axios
            .post(url, data, config)
            .then(
              response => {
                var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                var fileLink = document.createElement('a');

                fileLink.href = fileURL;
                fileLink.setAttribute('download', FileName);

                document.body.appendChild(fileLink);
                fileLink.click();
                document.body.removeChild(fileLink);
                resolve();
              },
              err => {
                reject(err);
              }
            )
            .catch(function (error: any) {
              reject(error);
            });

      }
      catch (error) {
        reject(error);
      };

    });
  }
  async exportReport(): Promise<void> {
    const self = this;
    return new Promise(function (resolve, reject) {
      try {
        const FileName = self.report.reportData.name + '.xlsx';
        const url = baseURL + self.report.reportData.name;

        let format = ExportFormat.XLSX;
        let data = self.exportData;
        //console.log("exportdata",data);

        //let sdata = JSON.stringify(data);
        //console.log("strexportdata", sdata);


        const config: AxiosRequestConfig = {
          responseType: "blob",
        };
        return axios
          .post(url, data, config)
          .then(
            response => {
              var fileURL = window.URL.createObjectURL(new Blob([response.data]));
              var fileLink = document.createElement('a');

              fileLink.href = fileURL;
              fileLink.setAttribute('download', FileName);

              document.body.appendChild(fileLink);
              fileLink.click();
              document.body.removeChild(fileLink);
              resolve();
            },
            err => {
              reject(err);
            }
          )
          .catch(function (error: any) {
            reject(error);
          });
        }
        catch(error) {
          reject(error);
        };
    });
  }
}
