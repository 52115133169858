import Vue from 'vue'
import Vuex from 'vuex'
import { StoreOptions } from 'vuex'
import { RootState } from './stateDefinition/rootState'
import ReportModule from './modules/reportModule'

import { vuexOidcCreateStoreModule } from 'vuex-oidc'

Vue.use(Vuex)

import { Log } from 'oidc-client';

const debugValue = (window as any).VueConfig?.OidcDebug;
Log.logger = console;
if (debugValue != null && debugValue == true) {
  console.log("debug oidc enabled", debugValue);
  Log.level = Log.DEBUG;
}
else {
  Log.level = Log.ERROR;
}

const store: StoreOptions<RootState> = {
    modules: {
        oidcStore: vuexOidcCreateStoreModule(
          (window as any).VueConfig.VUE_APP_OIDC_CONFIG,
            {
                namespaced: true,
                dispatchEventsOnWindow: true
          },
          (debugValue != null && debugValue == true) ?
            // Optional OIDC event listeners
            {
              userLoaded: user => console.log('OIDC user is loaded:', user),
              userUnloaded: () => console.log('OIDC user is unloaded'),
              accessTokenExpiring: () => console.log('Access token will expire'),
              accessTokenExpired: () => console.log('Access token did expire'),
              silentRenewError: () => console.log('silent Renew Error'),
              userSignedOut: () => console.log('OIDC user is signed out'),
              oidcError: payload => console.log('OIDC error', payload),
              automaticSilentRenewError: payload => console.log('automatic Silent Renew Error', payload)
            } : {}
        ),
        reportState: ReportModule,
  }
}
export default new Vuex.Store(store)
