
























































































































import { reactive, watch, computed, onMounted, onBeforeUpdate, onActivated } from "@vue/composition-api";
import { defineComponent } from '@vue/composition-api';
import { IReportData, IChartButton, IParameterData } from "@/store/stateDefinition/reportState"
import { ReportService } from '@/services/reportService'
import IParameterDataDatePicker from '@/components/IParameterDataDatePicker.vue'
import GroupedMultiSelect from '@/components/GroupedMultiSelect.vue'
import TextComponent from '@/components/TextComponent.vue'
import SingleSelect from '@/components/SingleSelect.vue'
import IntSlider from '@/components/IntSlider.vue'
import TextboxComponent from '@/components/TextboxComponent.vue'
import { ExportService, ExportFormat } from '@/services/exportService'
import { Report } from '../services/report';
import store from '../store';
import { ReportActions } from '@/store/actions/reportActions'
import Multiselect from 'vue-multiselect'
import { useWindowSize } from 'vue-window-size';

export default defineComponent({
  name: 'ReportComponent',
  components: { IParameterDataDatePicker, GroupedMultiSelect, SingleSelect, Multiselect, IntSlider, TextComponent, TextboxComponent },
  props: {
    report: {
      type: Object,
      required: true
    },
    isDialog: {
      type: String,
      required: false
    }
  },
  setup(props: any, { emit }) {
    const reportData: IReportData = reactive(props.report);
    const isDialog = props.isDialog != null && props.isDialog == "true";
    if (!isDialog) {
      Report.setDateParameters(reportData);
    }
    const state = reactive({
      report: {},
      chart: {},
      loaded: false,
      busy: false,
      exportError: false,
      maxPages: 1,
      mCurrentPageId: 1,
      mrowsPerPage: '10',
      rowsPerPageOptions: ['10', '25', '50', '100', 'All'],
      chartHeight: 200,
      showReportDialog: false,
      reportDialog: {},
      reportDialogKey: 0,
      isDialog,
      vcardStyle: {
        maxHeight: "500px",
      }
    });
    const reportService = new ReportService();
    const itemFormat = reportService.TableItemFormat;

    const userSelectionChange = computed((): boolean => store.getters["reportState/userSelectionChange"])
    watch(userSelectionChange, (newValue, oldValue) => {
      let report = state.report as Report;
      if (report.clearChartAndData !== undefined) {
        report.clearChartAndData();
        state.loaded = false;
      }
    })

    const { width, height } = useWindowSize();
    watch(height, (newValue, oldValue) => {
      setMaxHeight();
    })
    const setMaxHeight = () => {
      if (state.isDialog) {
        state.vcardStyle.maxHeight = (0.8 * height.value).toString() + 'px';
      }
      else {
        state.vcardStyle.maxHeight = '100%';
      }
    }
    setMaxHeight();

    const currentPage = computed(
      {
        get: () => state.mCurrentPageId,
        set: (newId: number) => {
          if (state.loaded && newId <= state.maxPages && newId > 0 && newId != state.mCurrentPageId) {
            let report = state.report as Report;
            state.mCurrentPageId = newId;
            report.setPage(newId - 1);
            resizeChart();
          }
        }
      });
    const resizeChart = () => {
      let report = state.report as Report;
      if (report.reportData.resizeChart == true)
        (state.chart as any).setSize(null, 100 + report.visibleRowsCount * 20, false);
    }
    const rowsPerPage = computed(
      {
        get: () => state.mrowsPerPage,
        set: (newValue: string) => {
          if (state.loaded && newValue != state.mrowsPerPage && newValue !== null) {
            let report = state.report as Report;
            state.mrowsPerPage = newValue;
            if (newValue == 'All') {
              report.setPageSize(9999999);
            }
            else {
              report.setPageSize(parseInt(newValue));
            }
            currentPage.value = 1;
            resizeChart();
            state.maxPages = report.getMaxPages();
          }
        }
      });

    const afterChartCreate = (chart: Highcharts.Chart) => {
      state.chart = chart;
      chart.reflow();
    }

    const loadReportData = () => {
      let chart = state.chart as Highcharts.Chart;
      state.busy = true;
      reportService.loadReportData(reportData, chart)
        .then((nreport) => {
          state.report = nreport;
          state.maxPages = nreport.getMaxPages();
          state.loaded = true;
          rowsPerPage.value = '10';
          currentPage.value = 1;
          resizeChart();
          state.busy = false;
        })
        .catch((e) => {
          state.busy = false;
          if (typeof e === "string") {
            store.dispatch("reportState/" + ReportActions.SetErrorBar, e)
          } else {
            store.dispatch("reportState/" + ReportActions.SetErrorBar, 'Error loading the report')
          }
          console.log(e);
        })
    }
    const printReport = () => {
      window.print();
    }
    const exportReport = () => {
      let report = state.report as Report;
      if (report.tableData === undefined) {
        store.dispatch("reportState/" + ReportActions.SetErrorBar, "Report not loaded");
        return;
      }
      let exportService = new ExportService(report, ExportFormat.XLS);
      state.busy = true;
      exportService.exportReport()
        .then(() => {
          state.busy = false;
        })
        .catch(err => {
          store.dispatch("reportState/" + ReportActions.SetErrorBar, "Error exporting the report");
          state.busy = false;
        });
    }
    const getItemClass = (item: any): any => {
      return ['text', 'text', 'num'];
    }
    const customSort = reportService.tableCustomSort;
    const rowClass = (item: any) => {
      if (item.isTotal === undefined)
        return '';
      if (item['isTotal'] == 1)
        return 'total';
      else
        return '';
    }
    const chartButtonClick = (data: IChartButton) => {
      let report = state.report as Report;
      if (data.type == 'SerieSort')
        report.sortByColumn(data.column);
      else
        console.log('invalid action: ' + data.type)
    }

    const largeSize = (param: IParameterData): number => {
      if (param.type == 'date' || param.type == 'month')
        return 2;
      return 5;
    }
    const closeDialog = () => {
      emit('closeDialog')
    }
    onMounted(() => {
      loadReportData();
    });
    return {
      state,
      reportData,
      afterChartCreate,
      loadReportData,
      exportReport,
      reportService,
      getItemClass,
      customSort,
      rowClass,
      itemFormat,
      chartButtonClick,
      currentPage,
      rowsPerPage,
      largeSize,
      closeDialog,
      printReport
    }
  }
});
