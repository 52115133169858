
























import { defineComponent } from '@vue/composition-api';
import { reactive } from "@vue/composition-api";
import Multiselect from 'vue-multiselect'
import { IParameterData } from "@/store/stateDefinition/reportState"
import { ReportService } from '@/services/reportService'

export default defineComponent({
  props: {
    param: {
      type: Object,
      required: true
    }
  },
  components: {
    Multiselect
  },
  setup(props: any) {
    const parameterData: IParameterData = props.param;
    const reportService = new ReportService();

    const state = reactive({
      options:[] as any,
      selection: [] as any
    });

    const findOptionByVal = (value: any) => {
      for (let i = 0; i < state.options.length; i++) {          
        let m = state.options[i].items.find((w: any) => w.itemId == value);
        if (m !== undefined) {
          return m;
        }
      }
      return null;
    }
    const allOptions = () => {
      let rv: any = [];
      for (let i = 0; i < state.options.length; i++) {
        rv = rv.concat(state.options[i].items);
      }
      return rv;
    }

    reportService.loadGroupedMultiselect(parameterData.name).then((t) => {
      state.options = t;
      if (parameterData.value != null && parameterData.value.length > 0) {
        let selected:any = [];
        let values = parameterData.value.split(',');
        values.map(t => {
          let v = findOptionByVal(parameterData.value);
          if (v != null)
            selected.push(v);
        });
        state.selection = selected;
      }
    });

    const selectAll = () => {
      let selected: any = allOptions();
      parameterData.value = selected.map((t: any) => t.itemId).join(',');
      state.selection = selected;
    }
    const selectNone = () => {
      parameterData.value = "";
      state.selection = [];
    }

    const save = (items: any) => {
      parameterData.value = items.map((t: any) => t.itemId).join(',') || '';
    }
    return {
      state,
      save,
      parameterData,
      selectAll,
      selectNone
    }
  },
});
