



















import { defineComponent } from '@vue/composition-api';
import { reactive } from "@vue/composition-api";
import { watch } from "@vue/composition-api";
//import Multiselect from 'vue-multiselect'
import { IParameterData } from "@/store/stateDefinition/reportState"
import { ReportService } from '@/services/reportService'

export default defineComponent({
  props: {
    param: {
      type: Object,
      required: true
    }
  },
  components: {
    //Multiselect
  },
  watch: {
    search(val: string) {
      this.loadSelect(val);
      //alert(val);
      //val && val !== this.state.selection[0] && this.loadSelect(val)
    }
  },
  methods: {
    loadSelect(query: string) {
      if (query==null || query.length < 2) {
        ///this.state.options = [];
        return;
      }
      if (this.state.selection !=null && this.state.selection.label ==query) {
        return;
      }
      this.loading = true;
      const reportService = new ReportService();
      
      reportService.loadLocation(this.parameterData.name, query).then((t) => {
        //console.log("result",t)
        this.state.options = t;
      });
      this.loading = false;
    }

  },
  setup(props: any) {
    const parameterData: IParameterData = props.param;
    

    const state = reactive({
      options: [],
      selection: {label:""}
    });

    const save = (item: any) => {
      parameterData.value = JSON.stringify(item);//item.name;
    }
    const search = null;
    //alert(parameterData.name);
    return {
      state,
      save,
      parameterData,
      search
      
    }
  },
});
