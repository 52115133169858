export enum ReportActions {
  LoadHomeReport = 'LOAD_HOME_REPORT',
  LoadDashboardReports = 'LOAD_DASHBOARD_REPORTS',
  LoadDetailReport = 'LOAD_DETAIL_REPORT',
  LoadAirlines = 'LOAD_AIRLINES',
  LoadTimeZones = 'LOAD_TIMEZONES',
  LoadConfigurations = 'LOAD_CONFIGURATIONS',
  SetSelectedConfigs = "SET_SELECTED_CONFIGS",
  LoadReportsFile = 'LOAD_REPORTS_FILE',
  SetSelectedAirlines = 'SET_SELECTED_AIRLINES',
  SetSelectedTimeZone = 'SET_SELECTED_TIMEZONE',
  SetConfirmedSelectionChanges = 'SET_CONFIRMED_SELECTION_CHANGES',
  SetErrorBar = 'SET_ERRORBAR',
  SetSelectedReportId = 'SET_SELECTEDREPORTID',
  SetSelectedReportUrl = 'SET_SELECTEDREPORTURL',
  ShowDialogReport = 'SHOW_DIALOG_REPORT',
  LoadCookiePolicy = 'LOAD_COOKIE_POLICY',
  SaveCookiePolicy = 'SAVE_COOKIE_POLICY'
}
