var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.state.report.tableHeaders,"items":_vm.state.report.tableData,"hide-default-footer":"","dense":"","disable-sort":"","item-key":"item.passengerGUID","loading-text":"Please wait, loading passenger details..."},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Passenger Details")]),_c('v-spacer'),_c('span',{staticClass:"export2",attrs:{"title":"Export"}},[_c('v-btn',{attrs:{"block":"","color":"primary","disabled":!_vm.state.loaded,"loading":_vm.state.busy},on:{"click":_vm.exportReport}},[_vm._v(" Export details ")])],1)],1)]},proxy:true},{key:"item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('tr',_vm._l((headers),function(hdr){return _c('td',[_vm._v(" "+_vm._s(item[hdr.value])+" ")])}),0),_c('tr',[_c('td',{attrs:{"colspan":headers.length}},[_c('v-data-table',{attrs:{"headers":item.documents.tableHeaders,"items":item.documents.tableData,"hide-default-footer":"","disable-sort":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Documents")])],1)]},proxy:true},{key:"item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('tr',_vm._l((headers),function(hdr){return _c('td',[_vm._v(" "+_vm._s(item[hdr.value])+" ")])}),0),(item.documentMessages != undefined  && item.documentMessages !=null &&  item.documentMessages.tableData.length>0)?_c('tr',[_c('td',{attrs:{"colspan":headers.length}},[_c('v-data-table',{attrs:{"headers":item.documentMessages.tableHeaders,"items":item.documentMessages.tableData,"hide-default-footer":"","disable-sort":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Messages")])],1)]},proxy:true}],null,true)})],1)]):_vm._e()]}}],null,true)})],1)]),_c('tr',[_c('td',{attrs:{"colspan":headers.length}},[_c('v-data-table',{attrs:{"headers":item.segments.tableHeaders,"items":item.segments.tableData,"hide-default-footer":"","disable-sort":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Segments/Messages")])],1)]},proxy:true},{key:"item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('tr',_vm._l((headers),function(hdr){return _c('td',[_vm._v(" "+_vm._s(item[hdr.value])+" ")])}),0),(item.level1Messages != undefined && item.level1Messages !=null && item.level1Messages.tableData.length>0)?_c('tr',[_c('td',{attrs:{"colspan":headers.length}},[_c('v-data-table',{attrs:{"headers":item.level1Messages.tableHeaders,"items":item.level1Messages.tableData,"hide-default-footer":"","disable-sort":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Level1 Messages")])],1)]},proxy:true}],null,true)})],1)]):_vm._e(),(item.level2Messages != undefined && item.level2Messages !=null && item.level2Messages.tableData.length>0)?_c('tr',[_c('td',{attrs:{"colspan":headers.length}},[_c('v-data-table',{attrs:{"headers":item.level2Messages.tableHeaders,"items":item.level2Messages.tableData,"hide-default-footer":"","disable-sort":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Level2 Messages")])],1)]},proxy:true}],null,true)})],1)]):_vm._e()]}}],null,true)})],1)])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }