import { GenerateOidcGetters } from '@/store/utils/oidc-store-helpers'
import axios from 'axios';
import store from '@/store'
const globalAxios = axios.create();
//{
//  baseURL: window.VueConfig.apiUrl,
//});
const req=axios.CancelToken
globalAxios.interceptors.request.use(
  config => {
    //return new Promise(function (resolve, reject) {
    //  const accessToken = GenerateOidcGetters().oidcAccessToken.value;
    //  config.headers["Authorization"] = `Bearer ${accessToken}`;
    //  config.headers["Content-Type"] = 'application/json';
    //  resolve(config);
    //});

    const accessToken = GenerateOidcGetters().oidcAccessToken.value;
    console.log(`Access tokent in axios inteceptor "${accessToken}"`)
    config.headers["Authorization"] = `Bearer ${accessToken}`;
    config.headers["Content-Type"] = 'application/json';

    //const user = GenerateOidcGetters().oidcUser.value;
    //console.log(`user  "${JSON.stringify(user)}"`)
    return config;

  },
  error => {
    Promise.reject(error);
  }
);

//// Response interceptor for API calls
//globalAxios.interceptors.response.use((response) => {
//  return response
//}, async function (error) {
//  const originalRequest = error.config;
//  if (error.response.status === 401 && !originalRequest._retry) {
//    originalRequest._retry = true;
//    store.dispatch('oidcStore/authenticateOidcSilent')
//      .then(() => {
//        return globalAxios(originalRequest);
//      });

//    //const access_token = await refreshAccessToken();
//    //axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
//    //return axiosApiInstance(originalRequest);
//  }
//  return Promise.reject(error);
//});

export default globalAxios;
